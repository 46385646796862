import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snack from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Close from '@material-ui/icons/Close';
import styles from './SnackbarContentStyles';
import Grid from '@material-ui/core/Grid';

const SnackbarContent = props => {
    const { classes, message, color, close, icon } = props;
    let closeButton = [];
    if (close !== undefined) {
        closeButton = [
            <IconButton
                className={classes.iconButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={props.onDismissClick}
            >
                <Close className={classes.close} />
            </IconButton>
        ];
    }
    let snackIcon = null;
    switch (typeof icon) {
        case 'object':
            snackIcon = <props.icon className={classes.icon} />;
            break;
        case 'string':
            snackIcon = <Icon className={classes.icon}>{props.icon}</Icon>;
            break;
        default:
            snackIcon = null;
            break;
    }

    return (
        <Snack
            message={
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>{snackIcon}</Grid>
                    <Grid item>
                        <span className={classes.messageContent}>
                            {message}
                        </span>
                    </Grid>
                    <Grid item>{close !== undefined ? closeButton : null}</Grid>
                </Grid>
            }
            classes={{
                root: classes.root + ' ' + classes[color],
                message: classes.message + ' ' + classes.container
            }}
        />
    );
};

SnackbarContent.propTypes = {
    message: PropTypes.node.isRequired,
    color: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'primary']),
    close: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onDismissClick: PropTypes.func
};

export default withStyles(styles)(SnackbarContent);
