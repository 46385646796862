import {
    createErrorNotification,
    createSuccessNotification
} from 'common/notificationFactory';

import {
    PUSH_ERROR_NOTIFICATION,
    PUSH_SUCCESS_NOTIFICATION,
    REMOVE_NOTIFICATION
} from 'data/actionTypes';

export const pushSuccessNotification = messageCode => dispatch => {
    const notification = createSuccessNotification(messageCode);
    setTimeout(
        () => dispatch(removeNotification(notification.id)),
        notification.timeout
    );
    return dispatch({
        type: PUSH_SUCCESS_NOTIFICATION,
        payload: notification
    });
};

export const pushErrorNotification = messageCode => dispatch => {
    const notification = createErrorNotification(messageCode);
    setTimeout(
        () => dispatch(removeNotification(notification.id)),
        notification.timeout
    );
    return dispatch({
        type: PUSH_ERROR_NOTIFICATION,
        payload: notification
    });
};

export const removeNotification = id => {
    return {
        type: REMOVE_NOTIFICATION,
        payload: id
    };
};
