import { container, title, selection } from 'common/CommonStyles';

const sloganStyles = {
    container: {
        zIndex: '12',
        color: '#FFFFFF',
        ...container,
        paddingRight: 15
    },
    parallaxTitle: {
        ...title,
        display: 'inline-block',
        position: 'relative',
        marginTop: '30px',
        minHeight: '32px',
        color: '#FFFFFF',
        textDecoration: 'none',
        '&::before': {
            backgroundColor: '#651AFB',
            top: 0,
            content: 'no-close-quote',
            height: '.25em',
            left: 0,
            marginTop: 1,
            position: 'absolute',
            right: '80%',
            zIndex: '-1'
        },
        '&::after': {
            backgroundColor: '#651AFB',
            bottom: 0,
            content: 'no-close-quote',
            height: '.25em',
            left: '80%',
            marginBottom: 1,
            position: 'absolute',
            right: 0,
            zIndex: '-1'
        }
    },
    selection: {
        ...selection
    }
};
export default sloganStyles;
