import i18next from 'i18next';

const REQUIRED_FIELDS = ['fullName', 'email', 'message'];

const ContactUsFormValidation = values => {
    const errors = {};
    const errorMsg = i18next.t('validations.required');

    REQUIRED_FIELDS.forEach(field => {
        if (!values[field]) {
            errors[field] = errorMsg;
        }
    });
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = i18next.t('validations.invalidEmail');
    }
    return errors;
};

export default ContactUsFormValidation;
