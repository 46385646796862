import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CustomInput from '../../../components/CustomInput/CustomInput.js';
import Button from '../../../components/CustomButtons/Button.js';
import styles from './CareersFormStyles.js';
import CareersFormValidation from './CareersFormValidation';
import { sendCareersMessage } from '../../../data/actions/careersActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import bdmImage from '../../../images/bdm.jpg';
import enCommon from '../../../translations/en/common';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import WifiIcon from '@material-ui/icons/Wifi';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import ReactGA from 'react-ga';
import { getCookieConsentValue } from 'react-cookie-consent';
import { COOKIE_NAME } from '../../../components/Cookie/Cookie';

const BENEFIT = 'benefit';
const BENEFIT2 = 'benefit2';
const BENEFIT3 = 'benefit3';
const BENEFIT4 = 'benefit4';

const onButtonClick = googleEvent => {
    if (getCookieConsentValue(COOKIE_NAME)) {
        ReactGA.event({
            category: googleEvent.category,
            action: googleEvent.action
        });
    }
};

class CareersForm extends React.Component {
    onSubmit = values => {
        onButtonClick({
            category: 'Careers',
            action: 'onSubmit passed validation'
        });
        this.props.sendCareersMessage(values, this.props.reset);
    };

    renderIcon = benefit => {
        if (benefit === BENEFIT) {
            return <AttachMoneyIcon className={this.props.classes.icons} />;
        } else if (benefit === BENEFIT2) {
            return <WifiIcon className={this.props.classes.icons} />;
        } else if (benefit === BENEFIT3) {
            return <LocalLibraryIcon className={this.props.classes.icons} />;
        } else if (benefit === BENEFIT4) {
            return (
                <ConfirmationNumberIcon className={this.props.classes.icons} />
            );
        }
    };

    render() {
        const { classes, handleSubmit, careers, t } = this.props;

        const AVAILABLE_POSITIONS = [
            t('careersPage.careersForm.fieldLabels.position.bdm')
        ];

        const hiring = [
            {
                header: t('careersPage.careersForm.weAreHiring.bdm.title'),
                body: t('careersPage.careersForm.weAreHiring.bdm.description'),
                dayBasis: {
                    first: t(
                        'careersPage.careersForm.weAreHiring.bdm.dayBasis.first'
                    ),
                    second: t(
                        'careersPage.careersForm.weAreHiring.bdm.dayBasis.second'
                    ),
                    third: t(
                        'careersPage.careersForm.weAreHiring.bdm.dayBasis.third'
                    ),
                    fourth: t(
                        'careersPage.careersForm.weAreHiring.bdm.dayBasis.fourth'
                    ),
                    fifth: t(
                        'careersPage.careersForm.weAreHiring.bdm.dayBasis.fifth'
                    )
                },
                image: bdmImage,
                id: 'hiring'
            }
        ];

        const benefits = Object.keys(
            enCommon.careersPage.careersForm.whyJoinUs.benefits
        );

        return (
            <div className={classes.section}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={8}>
                        <Typography variant="h1" className={classes.title}>
                            {t('careersPage.careersForm.title')}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="textSecondary"
                            className={classes.description}
                        >
                            {t('careersPage.careersForm.description')}
                        </Typography>
                        <Grid container className={classes.cardContainer}>
                            {hiring.map((h, idx) => {
                                return (
                                    <Grid
                                        key={idx + 'hiring'}
                                        item
                                        xs={10}
                                        sm={10}
                                        md={12}
                                        xl={12}
                                        className={classes.cardItem}
                                    >
                                        <Card className={classes.card}>
                                            <CardMedia
                                                className={classes.media}
                                                image={h.image}
                                                title={h.body}
                                            />
                                            <CardContent>
                                                <Typography
                                                    gutterBottom
                                                    variant="h2"
                                                    component="h2"
                                                    className={
                                                        classes.selection
                                                    }
                                                >
                                                    {h.header}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className={
                                                        classes.selection
                                                    }
                                                >
                                                    {h.body}
                                                </Typography>
                                                <ul>
                                                    <li className={classes.li}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            className={
                                                                classes.selection
                                                            }
                                                        >
                                                            {h.dayBasis.first}
                                                        </Typography>
                                                    </li>
                                                    <li className={classes.li}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            className={
                                                                classes.selection
                                                            }
                                                        >
                                                            {h.dayBasis.second}
                                                        </Typography>
                                                    </li>
                                                    <li className={classes.li}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            className={
                                                                classes.selection
                                                            }
                                                        >
                                                            {h.dayBasis.third}
                                                        </Typography>
                                                    </li>
                                                    <li className={classes.li}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            className={
                                                                classes.selection
                                                            }
                                                        >
                                                            {h.dayBasis.fourth}
                                                        </Typography>
                                                    </li>
                                                    <li className={classes.li}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            className={
                                                                classes.selection
                                                            }
                                                        >
                                                            {h.dayBasis.fifth}
                                                        </Typography>
                                                    </li>
                                                </ul>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>

                        <Grid item>
                            <Grid
                                container
                                justify="space-around"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Typography
                                        variant="h1"
                                        className={classes.title}
                                    >
                                        {t(
                                            'careersPage.careersForm.whyJoinUs.title'
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {benefits.map((benefit, idx) => {
                                        return (
                                            <Grid
                                                container
                                                alignItems="center"
                                                key={idx}
                                            >
                                                <Grid item>
                                                    {this.renderIcon(benefit)}
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                        className={
                                                            classes.bottomPadding10
                                                        }
                                                    >
                                                        {t(
                                                            'careersPage.careersForm.whyJoinUs.benefits.' +
                                                                benefit
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>

                        <form onSubmit={handleSubmit(this.onSubmit)}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={11}
                                    sm={11}
                                    md={6}
                                    className={classes.fullName}
                                >
                                    <Field
                                        name="fullName"
                                        component={CustomInput}
                                        labelText={t(
                                            'careersPage.careersForm.fieldLabels.fullName'
                                        )}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={11} sm={11} md={6}>
                                    <Field
                                        name="email"
                                        component={CustomInput}
                                        labelText={t(
                                            'careersPage.careersForm.fieldLabels.email'
                                        )}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </Grid>
                                <Field
                                    name="position"
                                    component={CustomSelect}
                                    labelText={t(
                                        'careersPage.careersForm.fieldLabels.position.title'
                                    )}
                                    labelClassName={classes.description}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                >
                                    {AVAILABLE_POSITIONS.map(
                                        (position, index) => (
                                            <MenuItem
                                                value={position}
                                                key={index}
                                            >
                                                <Typography
                                                    variant="h4"
                                                    className={classes.position}
                                                >
                                                    {position}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    )}
                                </Field>
                                <Field
                                    name="message"
                                    component={CustomInput}
                                    labelText={t(
                                        'careersPage.careersForm.fieldLabels.message'
                                    )}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.textArea
                                    }}
                                    inputProps={{
                                        multiline: true,
                                        rows: 5
                                    }}
                                />
                                <Grid
                                    container
                                    alignItems="center"
                                    justify="center"
                                    direction="row"
                                    className={classes.buttonsContainer}
                                >
                                    {!careers.isLoading && (
                                        <Grid
                                            item
                                            xs={11}
                                            sm={11}
                                            md={4}
                                            className={classes.textCenter}
                                        >
                                            <Button
                                                color="accent"
                                                type="submit"
                                                disabled={careers.isLoading}
                                                onClick={() =>
                                                    onButtonClick({
                                                        category: 'Careers',
                                                        action:
                                                            'Join the team button clicked'
                                                    })
                                                }
                                            >
                                                <Typography variant="button">
                                                    {t(
                                                        'careersPage.careersForm.submitButton'
                                                    )}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    )}
                                    {careers.isLoading && (
                                        <Grid
                                            item
                                            className={classes.textCenter}
                                        >
                                            <CircularProgress
                                                className={classes.progress}
                                                color="secondary"
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

CareersForm.propTypes = {
    sendCareersMessage: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    sendCareersMessage: (payload, successCallback) =>
        dispatch(sendCareersMessage(payload, successCallback))
});

const mapStateToProps = state => ({
    careers: state.careers
});

CareersForm = reduxForm({
    form: 'contact-us-form',
    validate: CareersFormValidation
})(CareersForm);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
    withTranslation()
)(CareersForm);
