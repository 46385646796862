const notificationsStyles = {
    container: {
        position: 'fixed',
        right: '35px',
        zIndex: '10',
        bottom: '10px'
    }
};

export default notificationsStyles;
