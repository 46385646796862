import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import styles from './CompanyDetailsStyle.js';
import Grid from '@material-ui/core/Grid';
import devgenLogo from '../../../images/developersGen_logo.png';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessIcon from '@material-ui/icons/Business';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

class CompanyDetails extends React.Component {
    render() {
        const { classes, t } = this.props;
        return (
            <div className={classes.section}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={8}>
                        <Typography variant="h1" className={classes.title}>
                            {t('companyDetails.title')}
                        </Typography>
                        <Grid container>
                            <Grid item className={classes.logoContainer}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.media}
                                        image={devgenLogo}
                                        title="DevelopersGen"
                                    />
                                </Card>
                            </Grid>

                            <Grid item>
                                <Grid
                                    container
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <BusinessIcon
                                            className={classes.icons}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className={classes.companyDetails}
                                            variant="h4"
                                            color="textPrimary"
                                            component="p"
                                        >
                                            {t('companyDetails.uab')}
                                        </Typography>
                                        <Typography
                                            className={classes.companyDetails}
                                            variant="h4"
                                            color="textPrimary"
                                            component="p"
                                        >
                                            {t('companyDetails.code')}
                                        </Typography>
                                        <Typography
                                            className={classes.companyDetails}
                                            variant="h4"
                                            color="textPrimary"
                                            component="p"
                                        >
                                            {t('companyDetails.pvm')}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <LocationOnIcon
                                            className={classes.icons}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className={classes.companyDetails}
                                            variant="h4"
                                            color="textPrimary"
                                            component="p"
                                        >
                                            {t('companyDetails.address')}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <AccountBalanceIcon
                                            className={classes.icons}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className={classes.companyDetails}
                                            variant="h4"
                                            color="textPrimary"
                                            component="p"
                                        >
                                            {t('companyDetails.bank.name')}
                                        </Typography>
                                        <Typography
                                            className={classes.companyDetails}
                                            variant="h4"
                                            color="textPrimary"
                                            component="p"
                                        >
                                            {t('companyDetails.bank.acc')}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <PermPhoneMsgIcon
                                            className={classes.icons}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className={classes.companyDetails}
                                            variant="h4"
                                            color="textPrimary"
                                            component="p"
                                        >
                                            {t('companyDetails.phone')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    withTranslation()
)(CompanyDetails);
