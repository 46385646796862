import axios from 'axios';
import {
    pushErrorNotification,
    pushSuccessNotification
} from './notificationsActions';
import {
    SEND_CAREERS_EMAIL,
    SEND_CAREERS_EMAIL_FAILURE,
    SEND_CAREERS_EMAIL_SUCCESS
} from '../actionTypes';
import { SEND_CAREERS_MESSAGE } from '../apiEndpoints';

const sendCareersEmail = () => ({ type: SEND_CAREERS_EMAIL });
const sendCareersEmailSuccess = () => ({
    type: SEND_CAREERS_EMAIL_SUCCESS
});
const sendCareersEmailFailure = () => ({
    type: SEND_CAREERS_EMAIL_FAILURE
});

export const sendCareersMessage = (data, successCallback) => async dispatch => {
    dispatch(sendCareersEmail());
    try {
        let response = await axios.post(SEND_CAREERS_MESSAGE, data);
        dispatch(sendCareersEmailSuccess());
        const successCode =
            response && response.data && response.data.success
                ? response.data.success.code
                : null;
        dispatch(pushSuccessNotification(successCode));
        successCallback();
    } catch (e) {
        dispatch(sendCareersEmailFailure());
        const errorCode =
            e.response && e.response.data && e.response.data.error
                ? e.response.data.error.code
                : null;
        dispatch(pushErrorNotification(errorCode));
    }
};
