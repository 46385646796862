import {
    title,
    primaryColor,
    titleWithoutColor,
    selection
} from 'common/CommonStyles';

const serviceSectionStyles = {
    section: {
        textAlign: 'center'
    },
    title: {
        ...title,
        marginBottom: '1rem',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none'
    },
    titleWithoutColor: {
        ...titleWithoutColor,
        marginBottom: '1rem',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none',
        ...selection
    },
    description: {
        color: '#999'
    },
    media: {
        height: 200
    },
    card: {
        maxWidth: 345,
        position: 'relative',
        '&::before': {
            backgroundColor: '#651AFB',
            top: 0,
            content: 'no-close-quote',
            height: '.25em',
            left: 0,
            position: 'absolute',
            right: '80%'
        },
        '&::after': {
            backgroundColor: '#651AFB',
            bottom: 0,
            content: 'no-close-quote',
            height: '.25em',
            left: '80%',
            position: 'absolute',
            right: 0
        }
    },
    servicesTitle: {
        marginBottom: 70
    },
    icons: {
        height: 38,
        width: 38,
        color: primaryColor
    },
    cardContainer: {
        '@media (min-width: 300px)': {
            justifyContent: 'center',
            marginRight: '35px'
        },
        '@media (min-width: 576px)': {
            justifyContent: 'space-between',
            marginRight: 0
        }
    },
    cardItem: {
        paddingBottom: 50,
        '@media only screen and (max-width : 1024px)': {
            padding: '10px 10px 50px 10px'
        },
        '@media only screen and (max-width : 1152px)': {
            padding: '10px 10px 50px 10px'
        }
    },
    cardContent: {
        height: 330,
        '@media only screen and (max-width : 1024px)': {
            height: 320
        },
        '@media only screen and (max-width : 1152px)': {
            height: 320
        },
        '@media only screen and (max-width : 800px)': {
            height: 340
        }
    }
};

export default serviceSectionStyles;
