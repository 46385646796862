import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import styles from './CustomSelectStyles.js';
import { Typography } from '@material-ui/core';
import InputErrorWarning from '../InputError/InputErrorWarning';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';

const CustomSelect = props => {
    const {
        classes,
        labelText,
        labelClassName,
        input,
        children,
        meta: { touched, error, warning, active }
    } = props;
    const labelClasses = classNames({
        [' ' + classes.labelRootError]: touched && !!error
    });
    return (
        <FormControl className={classes.formControl}>
            {labelText !== undefined ? (
                <InputLabel>
                    <Typography
                        variant="caption"
                        className={labelClassName + ' ' + labelClasses}
                    >
                        {labelText}
                    </Typography>
                </InputLabel>
            ) : null}
            <Select className={classes.underline} {...input}>
                {children}
            </Select>
            <InputErrorWarning
                touched={touched}
                error={error}
                active={active}
                warning={warning}
            />
        </FormControl>
    );
};

CustomSelect.propTypes = {
    labelText: PropTypes.node,
    white: PropTypes.bool,
    labelClassName: PropTypes.string
};

export default withStyles(styles)(CustomSelect);
