import React from 'react';
import PropTypes from 'prop-types';
import styles from './GridContainerStyles';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const GridContainer = props => {
    const { classes, children, className, ...rest } = props;
    return (
        <Grid container {...rest} className={classes.grid + ' ' + className}>
            {children}
        </Grid>
    );
};

GridContainer.defaultProps = {
    className: ''
};

GridContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default withStyles(styles)(GridContainer);
