// notifications
export const PUSH_SUCCESS_NOTIFICATION = 'PUSH_SUCCESS_NOTIFICATION';
export const PUSH_ERROR_NOTIFICATION = 'PUSH_ERROR_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// contact us email
export const SEND_CONTACT_US_EMAIL = 'SEND_CONTACT_US_EMAIL';
export const SEND_CONTACT_US_EMAIL_SUCCESS = 'SEND_CONTACT_US_EMAIL_SUCCESS';
export const SEND_CONTACT_US_EMAIL_FAILURE = 'SEND_CONTACT_US_EMAIL_FAILURE';

// careers email
export const SEND_CAREERS_EMAIL = 'SEND_CAREERS_EMAIL';
export const SEND_CAREERS_EMAIL_SUCCESS = 'SEND_CAREERS_EMAIL_SUCCESS';
export const SEND_CAREERS_EMAIL_FAILURE = 'SEND_CAREERS_EMAIL_FAILURE';
