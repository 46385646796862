import CookieConsent, {
    getCookieConsentValue,
    Cookies
} from 'react-cookie-consent';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { withStyles } from '@material-ui/core/styles';
import styles from './CookieStyles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Button from '../CustomButtons/Button';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

export const COOKIE_NAME = 'DEVELOPERSGEN-COOKIE-CONSENT';

const Cookie = props => {
    const { classes, t } = props;
    const [cookieConsentValue, setCookieConsentValue] = useState(
        getCookieConsentValue(COOKIE_NAME)
    );
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    useEffect(() => {
        if (cookieConsentValue) {
            handleAcceptCookie();
        }
    }, [cookieConsentValue]);

    useEffect(() => {
        setCookieConsentValue(getCookieConsentValue(COOKIE_NAME));
    }, [isButtonClicked]);

    const handleAcceptCookie = () => {
        Cookies.set(COOKIE_NAME, true, { secure: true });

        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
        ReactGA.pageview('/');

        setIsButtonClicked(true);
    };

    if (cookieConsentValue) {
        return null;
    }

    return (
        <CookieConsent
            containerClasses={classes.containerClasses}
            buttonClasses={classes.buttonClasses}
            onAccept={handleAcceptCookie}
            ButtonComponent={() => (
                <Button
                    size="sm"
                    className={classes.buttonClasses}
                    color="accent"
                    onClick={handleAcceptCookie}
                >
                    {t('cookie.understand')}
                </Button>
            )}
            contentClasses={classes.contentClasses}
        >
            <Typography variant="body2" color="textSecondary">
                {t('cookie.title')}
            </Typography>
        </CookieConsent>
    );
};

Cookie.propTypes = {
    className: PropTypes.string
};

export default compose(
    withStyles(styles),
    withTranslation()
)(Cookie);
