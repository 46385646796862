import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import support from '../../../../images/support.png';
import LearnMoreCommon from '../LearnMoreCommon';

const CustomerSupportPage = props => {
    const { t } = props;
    return (
        <LearnMoreCommon
            cardMediaImage={support}
            cardMediaTitle={t(
                'landingPage.serviceSection.services.customerSupport.header'
            )}
            headerTitle={t(
                'landingPage.serviceSection.services.customerSupport.header'
            )}
            firstBlockText={t(
                'landingPage.serviceSection.services.customerSupport.learnMore.firstBlock'
            )}
            contactUsDescription={t(
                'landingPage.serviceSection.services.customerSupport.learnMore.contactUs'
            )}
            googleEventCategory="Services - CustomerSupportPage"
        />
    );
};

export default compose(withTranslation())(CustomerSupportPage);
