import Check from '@material-ui/icons/Check';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

let id = 0;
const defaultSuccessCode = 'success.default';
const defaultErrorCode = 'error.somethingWentWrong';

const options = {
    close: true,
    timeout: 5000
};

export function createSuccessNotification(messageCode) {
    return {
        ...options,
        id: id++,
        color: 'success',
        icon: Check,
        messageCode: messageCode || defaultSuccessCode
    };
}

export function createErrorNotification(messageCode) {
    return {
        ...options,
        id: id++,
        color: 'danger',
        icon: ErrorOutline,
        messageCode: messageCode || defaultErrorCode
    };
}
