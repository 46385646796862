import { primaryColor, title, selection } from 'common/CommonStyles';

const companyDetailsStyles = {
    section: {
        paddingBottom: '70px'
    },
    title: {
        ...title,
        marginBottom: '50px',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none',
        textAlign: 'center',
        '@media (min-width: 300px)': {
            marginRight: 35
        },
        '@media (min-width: 576px)': {
            marginRight: 0
        }
    },
    description: {
        color: '#999',
        textAlign: 'center'
    },
    textCenter: {
        textAlign: 'center'
    },
    textArea: {
        marginRight: '15px',
        marginLeft: '15px'
    },
    card: {
        marginRight: 10,
        borderRadius: 0,
        '@media (min-width: 300px)': {
            height: 98,
            width: 98
        },
        '@media (min-width: 576px)': {
            height: 125,
            width: 125
        }
    },
    media: {
        '@media (min-width: 300px)': {
            height: 98,
            width: 98
        },
        '@media (min-width: 576px)': {
            height: 125,
            width: 125
        }
    },
    companyDetails: {
        ...selection,
        '@media (min-width: 300px)': {
            fontSize: 12
        },
        '@media (min-width: 576px)': {
            fontSize: 16
        }
    },
    icons: {
        width: '20px',
        height: '20px',
        marginRight: '3px',
        color: primaryColor
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center'
    }
};

export default companyDetailsStyles;
