import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from './OurValuesSectionStyles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StarIcon from '@material-ui/icons/Star';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

const POWER = 'POWER';
const WIN = 'WIN';
const GOLF = 'GOLF';
const SYNC = 'SYNC';
const FAVORITE = 'FAVORITE';
const STAR = 'STAR';
const SIMPLE_DOT = 'SIMPLE_DOT';

class OurValuesSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderIcon = icon => {
        if (icon === FAVORITE) {
            return <FavoriteIcon className={this.props.classes.icons} />;
        } else if (icon === STAR) {
            return <StarIcon className={this.props.classes.icons} />;
        } else if (icon === SIMPLE_DOT) {
            return <Brightness1Icon className={this.props.classes.icons} />;
        } else if (icon === GOLF) {
            return <GolfCourseIcon className={this.props.classes.icons} />;
        } else if (icon === POWER) {
            return <FlashOnIcon className={this.props.classes.icons} />;
        } else if (icon === SYNC) {
            return <SyncAltIcon className={this.props.classes.icons} />;
        } else if (icon === WIN) {
            return <EmojiEventsIcon className={this.props.classes.icons} />;
        }
    };

    render() {
        const { classes, t } = this.props;
        const values = [
            {
                header: t('ourValues.passion.title'),
                icon: FAVORITE
            },
            {
                header: t('ourValues.quality.title'),
                icon: STAR
            },
            {
                header: t('ourValues.simplicity.title'),
                icon: SIMPLE_DOT
            }
        ];

        const values2 = [
            {
                header: t('ourValues.teamwork.title'),
                icon: POWER
            },
            {
                header: t('ourValues.fidelity.title'),
                icon: SYNC
            },
            {
                header: t('ourValues.reward.title'),
                icon: WIN
            }
        ];

        return (
            <div className={classes.section}>
                <Grid
                    container
                    justify="center"
                    className={classes.servicesTitle}
                >
                    <Grid item xs={10} sm={10} md={8}>
                        <Typography variant="h1" className={classes.title}>
                            {t('ourValues.title')}
                        </Typography>
                        <Typography
                            variant="h4"
                            className={classes.titleWithoutColor}
                            color="textSecondary"
                        >
                            {t('ourValues.description')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.cardContainer}>
                    {values.map((service, idx) => {
                        return (
                            <Grid
                                item
                                key={'cardContainer' + idx}
                                xs={10}
                                sm={3}
                                md={3}
                                xl={4}
                                className={classes.cardItem}
                            >
                                <Card className={classes.card}>
                                    <CardContent>
                                        {this.renderIcon(service.icon)}
                                        <Typography
                                            gutterBottom
                                            variant="h2"
                                            component="h2"
                                            className={classes.selection}
                                        >
                                            {service.header}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.cardContainer}
                >
                    {values2.map((service, idx) => {
                        return (
                            <Grid
                                item
                                key={'cardContainer2' + idx}
                                xs={10}
                                sm={3}
                                md={3}
                                xl={4}
                                className={classes.cardItem}
                            >
                                <Card className={classes.card}>
                                    <CardContent>
                                        {this.renderIcon(service.icon)}
                                        <Typography
                                            gutterBottom
                                            variant="h2"
                                            component="h2"
                                            className={classes.selection}
                                        >
                                            {service.header}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withRouter
)(OurValuesSection);
