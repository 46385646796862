import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import webSystems from '../../../../images/webSystems.png';
import LearnMoreCommon from '../LearnMoreCommon';

const WebSystemsDevelopmentPage = props => {
    const { t } = props;

    return (
        <LearnMoreCommon
            cardMediaImage={webSystems}
            cardMediaTitle={t(
                'landingPage.serviceSection.services.webSystemsDevelopment.header'
            )}
            headerTitle={t(
                'landingPage.serviceSection.services.webSystemsDevelopment.header'
            )}
            firstBlockText={t(
                'landingPage.serviceSection.services.webSystemsDevelopment.learnMore.firstBlock'
            )}
            contactUsDescription={t(
                'landingPage.serviceSection.services.webSystemsDevelopment.learnMore.contactUs'
            )}
            googleEventCategory="Services - WebSystemsDevelopmentPage"
        />
    );
};

export default compose(withTranslation())(WebSystemsDevelopmentPage);
