import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';

import styles from './AboutUsPageStyles';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import WhyUsSection from '../CommonSections/WhyUs/WhyUsSection';
import HowWeWorkSection from '../CommonSections/HowWeWork/HowWeWorkSection';
import OurValuesSection from '../CommonSections/OurValues/OurValuesSection';
import ContactUsForm from '../ContactUsPage/Form/ContactUsForm';
import CompanyDetails from '../CommonSections/CompanyDetails/CompanyDetails';
import Slogan from '../../components/Slogan/Slogan';
import { HEADER_LINK_IDS } from '../../components/Header/HeaderLinks';

const AboutUsPage = props => {
    const { classes, t } = props;
    return (
        <div>
            <Header
                color="transparent"
                fixed
                changeColorOnScroll={{
                    height: 1,
                    color: 'white'
                }}
                activeTab={HEADER_LINK_IDS.ABOUT_US}
            />
            <Slogan parallax="parallax90hv" />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className={classes.section}>
                        <Grid container direction="column">
                            <Grid item className={classes.bottomPadding50}>
                                <Grid
                                    container
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Typography
                                            variant="h1"
                                            className={classes.title}
                                        >
                                            {t('aboutUsPage.whoAreWe.title')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        className={classes.whoAreWeDescription}
                                    >
                                        <Typography
                                            variant="h4"
                                            color="textSecondary"
                                            className={classes.selection}
                                        >
                                            {t(
                                                'aboutUsPage.whoAreWe.description'
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={classes.marginRight}>
                                <HowWeWorkSection />
                            </Grid>

                            <Grid item className={classes.marginRight}>
                                <OurValuesSection />
                            </Grid>

                            <Grid item className={classes.marginRight}>
                                <WhyUsSection />
                            </Grid>

                            <Grid item>
                                <ContactUsForm
                                    descriptionAdditional={t(
                                        'contactUsPage.contactUsForm.descriptionAdditional'
                                    )}
                                    googleEventCategory="About us"
                                />
                                <CompanyDetails />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default compose(
    withStyles(styles),
    withTranslation(),
    withRouter
)(AboutUsPage);
