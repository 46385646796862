import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import styles from './ScrollDownHintStyles.js';
import { withStyles } from '@material-ui/core/styles';

class ScrollDownHint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: window.pageYOffset === 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        this.setState({ show: window.pageYOffset === 0 });
    };

    render() {
        const { show } = this.state;
        const { classes } = this.props;

        return (
            show && (
                <div className={classes.mainContainer}>
                    <div className={classes.subContainer}>
                        <svg className={classes.arrows}>
                            <path
                                className={`${classes.path} ${classes.a}`}
                                d="M0 0 L30 32 L60 0"
                            />
                            <path
                                className={`${classes.path} ${classes.a2}`}
                                d="M0 20 L30 52 L60 20"
                            />
                            <path
                                className={`${classes.path} ${classes.a3}`}
                                d="M0 40 L30 72 L60 40"
                            />
                        </svg>
                    </div>
                </div>
            )
        );
    }
}

export default compose(
    withStyles(styles),
    withTranslation()
)(ScrollDownHint);
