import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/CustomButtons/Button.js';

import styles from './HeaderLinksStyles.js';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { getCookieConsentValue } from 'react-cookie-consent';
import { COOKIE_NAME } from '../Cookie/Cookie';

const outboundLink = (url, label) => {
    if (getCookieConsentValue(COOKIE_NAME)) {
        return ReactGA.outboundLink(
            {
                label: label
            },
            () => {
                window.open(url, '_blank');
            },
            ['social-media']
        );
    }

    return window.open(url, '_blank');
};

export const HEADER_LINK_IDS = {
    HOME: 'HOME',
    SERVICES: 'SERVICES',
    ABOUT_US: 'ABOUT_US',
    CAREERS: 'CAREERS',
    CONTACT_US: 'CONTACT_US'
};

const LINKS = [
    {
        id: HEADER_LINK_IDS.SERVICES,
        path: '/services',
        labelCode: 'header.services'
    },
    {
        id: HEADER_LINK_IDS.ABOUT_US,
        path: '/about-us',
        labelCode: 'header.aboutUs'
    },
    {
        id: HEADER_LINK_IDS.CAREERS,
        path: '/careers',
        labelCode: 'header.careers'
    },
    {
        id: HEADER_LINK_IDS.CONTACT_US,
        path: '/contact-us',
        labelCode: 'header.contactUs'
    }
];

const SOCIAL_LINKS = [
    {
        title: 'Linkedin',
        url: 'https://linkedin.com/company/developersgen?ref=developersgen',
        icon: 'fab fa-linkedin',
        labelCode: 'header.socialTooltip.linkedin'
    },
    {
        title: 'Facebook',
        url: 'https://www.facebook.com/developersgen?ref=developersgen',
        icon: 'fab fa-facebook',
        labelCode: 'header.socialTooltip.facebook'
    },
    {
        title: 'Instagram',
        url: 'https://www.instagram.com/developersgen?ref=developersgen',
        icon: 'fab fa-instagram',
        labelCode: 'header.socialTooltip.instagram'
    }
];

const HeaderLinks = props => {
    const { classes, history, t, activeTab } = props;
    return (
        <List className={classes.list}>
            {LINKS.map(link => (
                <ListItem className={classes.listItem} key={link.id}>
                    <Button
                        color="transparent"
                        className={
                            activeTab === link.id
                                ? classes.navLinkActive
                                : classes.navLink
                        }
                        onClick={() => history.push(link.path)}
                    >
                        <Typography variant="button">
                            {t(link.labelCode)}
                        </Typography>
                    </Button>
                </ListItem>
            ))}
            {SOCIAL_LINKS.map(socialLink => (
                <ListItem className={classes.listItem} key={socialLink.title}>
                    <Tooltip
                        id={socialLink.title}
                        title={
                            <Typography variant="caption">
                                {t(socialLink.labelCode)}
                            </Typography>
                        }
                        placement={window.innerWidth > 959 ? 'top' : 'left'}
                        classes={{ tooltip: classes.tooltip }}
                    >
                        <Button
                            onClick={() =>
                                outboundLink(socialLink.url, socialLink.title)
                            }
                            color="transparent"
                            className={classes.navLink}
                        >
                            <i
                                className={
                                    classes.socialIcons + ' ' + socialLink.icon
                                }
                            />
                        </Button>
                    </Tooltip>
                </ListItem>
            ))}
        </List>
    );
};

export default compose(
    withStyles(styles),
    withTranslation(),
    withRouter
)(HeaderLinks);
