import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';

import styles from './ContactUsPageStyles';
import ContactUsForm from './Form/ContactUsForm';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import CompanyDetails from '../CommonSections/CompanyDetails/CompanyDetails';
import Slogan from '../../components/Slogan/Slogan';
import { HEADER_LINK_IDS } from '../../components/Header/HeaderLinks';

const ContactUsPage = props => {
    const { classes, t } = props;
    return (
        <div>
            <Header
                color="transparent"
                fixed
                changeColorOnScroll={{
                    height: 1,
                    color: 'white'
                }}
                activeTab={HEADER_LINK_IDS.CONTACT_US}
            />
            <Slogan parallax="parallax90hv" />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <ContactUsForm
                        descriptionAdditional={t(
                            'contactUsPage.contactUsForm.descriptionAdditional'
                        )}
                        googleEventCategory="Contact us"
                    />
                    <CompanyDetails />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default compose(
    withStyles(styles),
    withTranslation()
)(ContactUsPage);
