import { container, title, sectionContainer } from 'common/CommonStyles';

const servicesPageStyles = {
    container: {
        zIndex: '12',
        color: '#FFFFFF',
        ...container
    },
    sectionContainer: {
        zIndex: '12',
        color: '#FFFFFF',
        ...sectionContainer,
        paddingBottom: 50
    },
    title: {
        ...title,
        display: 'inline-block',
        position: 'relative',
        marginTop: '30px',
        minHeight: '32px',
        color: '#FFFFFF',
        textDecoration: 'none',
        '&::before': {
            backgroundColor: '#651AFB',
            top: 0,
            content: 'no-close-quote',
            height: '.25em',
            left: 0,
            marginTop: 1,
            position: 'absolute',
            right: '80%',
            zIndex: '-1'
        },
        '&::after': {
            backgroundColor: '#651AFB',
            bottom: 0,
            content: 'no-close-quote',
            height: '.25em',
            left: '80%',
            marginBottom: 1,
            position: 'absolute',
            right: 0,
            zIndex: '-1'
        }
    },
    main: {
        background: '#FFFFFF',
        position: 'relative',
        zIndex: '3'
    },
    mainRaised: {
        '@media (min-width: 300px)': {
            margin: '-60px 15px 0px'
        },
        '@media (min-width: 576px)': {
            margin: '-60px 30px 0px'
        },
        borderRadius: '6px',
        boxShadow:
            '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
    }
};

export default servicesPageStyles;
