import { title, primaryColor, selection } from 'common/CommonStyles';

const careersFormStyles = {
    section: {
        padding: '70px 0'
    },
    title: {
        ...title,
        marginBottom: '50px',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none',
        textAlign: 'center',
        '@media (min-width: 300px)': {
            marginRight: 35
        },
        '@media (min-width: 576px)': {
            marginRight: 0
        }
    },
    description: {
        textAlign: 'center',
        paddingBottom: 50,
        ...selection,
        '@media (min-width: 300px)': {
            marginRight: 35
        },
        '@media (min-width: 576px)': {
            marginRight: 0
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    textArea: {
        '@media (min-width: 300px)': {
            marginRight: '35px'
        },
        '@media (min-width: 576px)': {
            marginRight: '0'
        }
    },
    fullName: {
        '@media (min-width: 300px)': {
            paddingRight: '0'
        },
        '@media (min-width: 576px)': {
            paddingRight: '35px'
        }
    },
    formControl: {
        '@media (min-width: 300px)': {
            minWidth: '90%'
        },
        '@media (min-width: 576px)': {
            minWidth: '100%'
        }
    },
    underline: {
        '&:after': {
            borderBottom: `2px solid ${primaryColor}`
        }
    },
    card: {
        maxWidth: 800,
        '@media (min-width: 300px)': {
            maxWidth: 345
        },
        '@media (min-width: 576px)': {
            maxWidth: 800
        }
    },
    media: {
        height: 240,
        '&:before': {
            background: 'rgba(0, 0, 0, 0.5)'
        },
        '&:after,&:before': {
            zIndex: '1',
            width: '100%',
            height: '100%',
            display: 'block',
            left: '0',
            top: '0',
            content: "''"
        }
    },
    buttonsContainer: {
        '@media (min-width: 300px)': {
            marginRight: '35px'
        },
        '@media (min-width: 576px)': {
            marginRight: '0'
        }
    },
    cardContainer: {
        justifyContent: 'center',
        '@media (min-width: 300px)': {
            marginRight: '35px'
        },
        '@media (min-width: 576px)': {
            marginRight: 0
        }
    },
    cardItem: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 50,
        '@media (min-width: 300px)': {
            marginRight: '35px'
        },
        '@media (min-width: 576px)': {
            marginRight: 0
        }
    },
    bottomPadding10: {
        paddingBottom: 10,
        ...selection
    },
    icons: {
        color: primaryColor,
        display: 'flex',
        paddingBottom: 10,
        paddingRight: 5
    },
    li: {
        color: '#651AFB',
        listStyleType: 'square'
    },
    selection: {
        ...selection
    },
    position: {
        color: 'rgba(0, 0, 0, 0.54)'
    }
};

export default careersFormStyles;
