import { container, primaryColor, selection, title } from 'common/CommonStyles';

const learnMoreCommonStyles = {
    container: {
        zIndex: '12',
        color: '#FFFFFF',
        ...container
    },
    section: {
        paddingBottom: 70
    },
    main: {
        background: '#FFFFFF',
        position: 'relative',
        zIndex: '3',
        '&::before': {
            backgroundColor: '#651AFB',
            top: 0,
            content: 'no-close-quote',
            height: '.25em',
            left: 0,
            position: 'absolute',
            right: '80%',
            zIndex: -1
        },
        '&::after': {
            backgroundColor: '#651AFB',
            bottom: 0,
            content: 'no-close-quote',
            height: '.25em',
            left: '80%',
            position: 'absolute',
            right: 0
        }
    },
    mainRaised: {
        '@media (min-width: 300px)': {
            margin: '-60px 15px 0px'
        },
        '@media (min-width: 576px)': {
            margin: '-60px 30px 0px'
        },
        borderRadius: 6,
        boxShadow:
            '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
    },
    media: {
        height: 200,
        width: 345,
        '@media (min-width: 300px)': {
            width: 300
        },
        '@media (min-width: 576px)': {
            width: 345
        }
    },
    card: {
        maxWidth: 345,
        '@media (min-width: 300px)': {
            maxWidth: 300
        },
        '@media (min-width: 576px)': {
            maxWidth: 345
        }
    },
    imageItem: {
        marginTop: -100,
        '@media (min-width: 300px)': {
            marginRight: 32
        },
        '@media (min-width: 576px)': {
            marginRight: 0
        }
    },
    title: {
        ...title,
        marginBottom: '50px',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none',
        textAlign: 'center',
        '@media (min-width: 300px)': {
            marginRight: 35
        },
        '@media (min-width: 576px)': {
            marginRight: 0
        }
    },
    icons: {
        height: 38,
        width: 38,
        color: primaryColor
    },
    iconContainer: {
        textAlign: 'center',
        position: 'relative',
        top: 20
    },
    firstBlock: {
        paddingBottom: 50,
        textAlign: 'justify',
        hyphens: 'auto',
        '@media (min-width: 300px)': {
            marginRight: 30
        },
        '@media (min-width: 576px)': {
            marginRight: 150,
            marginLeft: 150
        }
    },
    '@keyframes move-to-left': {
        from: { right: -500 },
        to: { right: 0 }
    },
    firstBlockImage: {
        position: 'relative',
        animationTimingFunction: 'ease',
        animation: '$move-to-right 1s'
    },
    '@keyframes move-to-right': {
        from: { left: -500 },
        to: { left: 0 }
    },
    secondBlock: {
        position: 'relative',
        maxWidth: 500,
        paddingRight: 20,
        animationTimingFunction: 'ease',
        animation: '$move-to-right 1s',
        display: 'flex',
        alignItems: 'center'
    },
    secondBlockTech: {
        position: 'relative',
        animationTimingFunction: 'ease',
        animation: '$move-to-left 1s'
    },
    bottomPadding50: {
        paddingBottom: 50
    },
    bottomPadding10: {
        paddingBottom: 10
    },
    thirdBlockTitle: {
        textAlign: 'center',
        paddingBottom: 50
    },
    marginRight: {
        '@media (min-width: 300px)': {
            marginRight: 35
        },
        '@media (min-width: 576px)': {
            marginRight: 0
        }
    },
    selection: {
        ...selection
    }
};

export default learnMoreCommonStyles;
