import axios from 'axios';
import {
    pushErrorNotification,
    pushSuccessNotification
} from './notificationsActions';
import {
    SEND_CONTACT_US_EMAIL,
    SEND_CONTACT_US_EMAIL_FAILURE,
    SEND_CONTACT_US_EMAIL_SUCCESS
} from '../actionTypes';
import { SEND_CONTACT_US_MESSAGE } from '../apiEndpoints';

const sendContactUsEmail = () => ({ type: SEND_CONTACT_US_EMAIL });
const sendContactUsEmailSuccess = () => ({
    type: SEND_CONTACT_US_EMAIL_SUCCESS
});
const sendContactUsEmailFailure = () => ({
    type: SEND_CONTACT_US_EMAIL_FAILURE
});

export const sendContactUsMessage = (
    data,
    successCallback
) => async dispatch => {
    dispatch(sendContactUsEmail());
    try {
        let response = await axios.post(SEND_CONTACT_US_MESSAGE, data);
        dispatch(sendContactUsEmailSuccess());
        const successCode =
            response && response.data && response.data.success
                ? response.data.success.code
                : null;
        dispatch(pushSuccessNotification(successCode));
        successCallback();
    } catch (e) {
        dispatch(sendContactUsEmailFailure());
        const errorCode =
            e.response && e.response.data && e.response.data.error
                ? e.response.data.error.code
                : null;
        dispatch(pushErrorNotification(errorCode));
    }
};
