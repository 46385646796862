import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import Header from '../../../components/Header/Header.js';
import Footer from '../../../components/Footer/Footer.js';

import styles from './LearnMoreCommonStyles';
import classNames from 'classnames';
import Parallax from '../../../components/Parallax/Parallax';
import landingImage from '../../../images/mainCover.jpg';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import WebIcon from '@material-ui/icons/Web';
import ContactUsForm from '../../ContactUsPage/Form/ContactUsForm';
import PartnersSection from '../Partners/PartnersSection';
import WhyUsSection from '../WhyUs/WhyUsSection';
import TechnologiesSection from '../Technologies/TechnologiesSection';

const LearnMoreCommon = props => {
    const {
        classes,
        cardMediaImage,
        cardMediaTitle,
        headerTitle,
        firstBlockText,
        contactUsDescription,
        googleEventCategory
    } = props;

    return (
        <div>
            <Header
                color="transparent"
                fixed
                changeColorOnScroll={{
                    height: 1,
                    color: 'white'
                }}
            />
            <Parallax parallax="parallax50hv" filter image={landingImage} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className={classes.section}>
                        <Grid container justify="center">
                            <Grid item className={classes.imageItem}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.media}
                                        image={cardMediaImage}
                                        title={cardMediaTitle}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                        <div className={classes.iconContainer}>
                            <WebIcon className={classes.icons} />
                        </div>
                        <Typography variant="h1" className={classes.title}>
                            {headerTitle}
                        </Typography>
                    </div>

                    <Grid container direction="column">
                        <Grid item className={classes.bottomPadding50}>
                            <Grid container justify="center">
                                <Grid item className={classes.firstBlock}>
                                    <Typography
                                        variant="h4"
                                        color="textSecondary"
                                        className={classes.selection}
                                    >
                                        {firstBlockText}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            className={`${classes.bottomPadding50} ${classes.marginRight}`}
                        >
                            <TechnologiesSection />
                        </Grid>

                        <Grid
                            item
                            className={`${classes.bottomPadding50} ${classes.marginRight}`}
                        >
                            <WhyUsSection />
                        </Grid>

                        <Grid item>
                            <ContactUsForm
                                description={contactUsDescription}
                                googleEventCategory={googleEventCategory}
                            />
                        </Grid>

                        <Grid item className={classes.marginRight}>
                            <PartnersSection />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default compose(
    withStyles(styles),
    withTranslation()
)(LearnMoreCommon);
