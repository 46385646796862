import React from 'react';
import { compose } from 'redux';
import ReactGA from 'react-ga';
import ContactsPage from './ContactUsPage/ContactUsPage';
import CareersPage from './CareersPage/CareersPage';
import { Route, Switch, withRouter } from 'react-router-dom';
import LandingPage from './LandingPage/LandingPage';
import AboutUsPage from './AboutUsPage/AboutUsPage';
import WebSystemsDevelopmentPage from './CommonSections/LearnMore/WebSystemsDevelopment/WebSystemsDevelopmentPage';
import MobileAppDevelopmentPage from './CommonSections/LearnMore/MobileAppDevelopment/MobileAppDevelopmentPage';
import CustomSoftwareDevelopmentPage from './CommonSections/LearnMore/CustomSoftwareDevelopment/CustomSoftwareDevelopmentPage';
import SoftwareMaintenanceAndEnhancementPage from './CommonSections/LearnMore/SoftwareMaintenanceAndEnhancement/SoftwareMaintenanceAndEnhancementPage';
import BusinessWorkFlowOptimizationPage from './CommonSections/LearnMore/BusinessWorkFlowOptimization/BusinessWorkFlowOptimizationPage';
import CustomerSupportPage from './CommonSections/LearnMore/CustomerSupport/CustomerSupportPage';
import ServicesPage from './ServicesPage/ServicesPage';
import { getCookieConsentValue } from 'react-cookie-consent';
import { COOKIE_NAME } from '../components/Cookie/Cookie';

class App extends React.Component {
    componentDidMount() {
        if (getCookieConsentValue(COOKIE_NAME)) {
            this.props.history.listen(location => {
                ReactGA.set({ page: location.pathname });
                ReactGA.pageview(location.pathname);
            });
        }
    }

    componentDidUpdate() {
        /* when new route is loaded then scroll to top */
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Switch>
                <Route path="/services" component={ServicesPage} />
                <Route path="/about-us" component={AboutUsPage} />
                <Route path="/careers" component={CareersPage} />
                <Route path="/contact-us" component={ContactsPage} />
                <Route
                    path="/web-systems-development"
                    component={WebSystemsDevelopmentPage}
                />
                <Route
                    path="/mobile-app-development"
                    component={MobileAppDevelopmentPage}
                />
                <Route
                    path="/custom-software-development"
                    component={CustomSoftwareDevelopmentPage}
                />
                <Route
                    path="/software-maintenance-and-enhancement"
                    component={SoftwareMaintenanceAndEnhancementPage}
                />
                <Route
                    path="/business-work-flow-optimization"
                    component={BusinessWorkFlowOptimizationPage}
                />
                <Route
                    path="/customer-support"
                    component={CustomerSupportPage}
                />
                <Route path="/" component={LandingPage} />
            </Switch>
        );
    }
}

export default compose(withRouter)(App);
