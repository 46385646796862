import {
    SEND_CONTACT_US_EMAIL,
    SEND_CONTACT_US_EMAIL_FAILURE,
    SEND_CONTACT_US_EMAIL_SUCCESS
} from '../actionTypes';

const initialState = {
    isLoading: false
};

const contactUsReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case SEND_CONTACT_US_EMAIL:
            return {
                isLoading: true
            };
        case SEND_CONTACT_US_EMAIL_SUCCESS: {
            return {
                isLoading: false
            };
        }
        case SEND_CONTACT_US_EMAIL_FAILURE:
            return {
                isLoading: false
            };
        default:
            return state;
    }
};

export default contactUsReducer;
