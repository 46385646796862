import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import CustomInput from '../../../components/CustomInput/CustomInput.js';
import Button from '../../../components/CustomButtons/Button.js';

import styles from './ContactUsFormStyles.js';
import ContactUsFormValidation from './ContactUsFormValidation';
import { sendContactUsMessage } from '../../../data/actions/contactUsActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import ReactGA from 'react-ga';
import { getCookieConsentValue } from 'react-cookie-consent';
import { COOKIE_NAME } from '../../../components/Cookie/Cookie';

const onButtonClick = googleEvent => {
    if (getCookieConsentValue(COOKIE_NAME)) {
        ReactGA.event({
            category: googleEvent.category,
            action: googleEvent.action
        });
    }
};

class ContactUsForm extends React.Component {
    onSubmit = values => {
        const { sendContactUsMessage, googleEventCategory } = this.props;
        onButtonClick({
            category: googleEventCategory,
            action: 'onSubmit passed validation'
        });
        sendContactUsMessage(values, this.props.reset);
    };

    render() {
        const {
            classes,
            handleSubmit,
            contactUs,
            t,
            description,
            descriptionAdditional,
            title
        } = this.props;
        return (
            <div className={classes.section}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={8}>
                        <Typography variant="h1" className={classes.title}>
                            {title
                                ? title
                                : t('contactUsPage.contactUsForm.title')}
                        </Typography>
                        <Typography
                            variant="h4"
                            className={classes.description}
                            color="textSecondary"
                        >
                            {!descriptionAdditional &&
                                (description
                                    ? description
                                    : t(
                                          'contactUsPage.contactUsForm.description'
                                      ))}
                        </Typography>
                        <Typography
                            variant="h4"
                            className={classes.description}
                            color="textSecondary"
                        >
                            {descriptionAdditional}
                        </Typography>
                        <form onSubmit={handleSubmit(this.onSubmit)}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={11}
                                    sm={11}
                                    md={6}
                                    className={classes.fullName}
                                >
                                    <Field
                                        name="fullName"
                                        component={CustomInput}
                                        labelText={t(
                                            'contactUsPage.contactUsForm.fieldLabels.fullName'
                                        )}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={11} sm={11} md={6}>
                                    <Field
                                        name="email"
                                        component={CustomInput}
                                        labelText={t(
                                            'contactUsPage.contactUsForm.fieldLabels.email'
                                        )}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </Grid>
                                <Field
                                    name="message"
                                    component={CustomInput}
                                    labelText={t(
                                        'contactUsPage.contactUsForm.fieldLabels.message'
                                    )}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.textArea
                                    }}
                                    inputProps={{
                                        multiline: true,
                                        rows: 5
                                    }}
                                />
                                <Grid
                                    container
                                    alignItems="center"
                                    justify="center"
                                    direction="row"
                                    className={classes.buttonsContainer}
                                >
                                    {!contactUs.isLoading && (
                                        <Grid
                                            item
                                            xs={11}
                                            sm={11}
                                            md={4}
                                            className={classes.textCenter}
                                        >
                                            <Button
                                                color="accent"
                                                type="submit"
                                                disabled={contactUs.isLoading}
                                                onClick={() =>
                                                    onButtonClick({
                                                        category: this.props
                                                            .googleEventCategory,
                                                        action:
                                                            'ContactUsForm - Send message button clicked'
                                                    })
                                                }
                                            >
                                                <Typography variant="button">
                                                    {t(
                                                        'contactUsPage.contactUsForm.submitButton'
                                                    )}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    )}
                                    {contactUs.isLoading && (
                                        <Grid
                                            item
                                            className={classes.textCenter}
                                        >
                                            <CircularProgress
                                                className={classes.progress}
                                                color="secondary"
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

ContactUsForm.propTypes = {
    sendContactUsMessage: PropTypes.func.isRequired,
    googleEventCategory: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => ({
    sendContactUsMessage: (payload, successCallback) =>
        dispatch(sendContactUsMessage(payload, successCallback))
});

const mapStateToProps = state => ({
    contactUs: state.contactUs
});

ContactUsForm = reduxForm({
    form: 'contact-us-form',
    validate: ContactUsFormValidation
})(ContactUsForm);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
    withTranslation()
)(ContactUsForm);
