import { dangerColor, warningColor } from 'common/CommonStyles';

const inputErrorWarningStyles = {
    error: {
        color: dangerColor
    },
    warning: {
        color: warningColor
    }
};

export default inputErrorWarningStyles;
