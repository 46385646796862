import {
    PUSH_ERROR_NOTIFICATION,
    PUSH_SUCCESS_NOTIFICATION,
    REMOVE_NOTIFICATION
} from '../actionTypes';

const notificationsReducer = (state = [], action) => {
    const { payload, type } = action;

    switch (type) {
        case PUSH_SUCCESS_NOTIFICATION:
        case PUSH_ERROR_NOTIFICATION: {
            return [payload, ...state];
        }
        case REMOVE_NOTIFICATION:
            return state.filter(toast => toast.id !== payload);
        default:
            return state;
    }
};

export default notificationsReducer;
