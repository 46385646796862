import { cardTitle, title, imagesStyles } from 'common/CommonStyles';

const technologiesSectionStyles = {
    section: {
        textAlign: 'center'
    },
    title: {
        ...title,
        marginBottom: '1rem',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none'
    },
    ...imagesStyles,
    techLogo: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 100,
        height: 100,
        cursor: 'pointer'
    },
    cardTitle,
    smallTitle: {
        color: '#6c757d'
    },
    description: {
        color: '#999'
    },
    justifyCenter: {
        justifyContent: 'center !important',
        padding: 0
    },
    margin5: {
        margin: '5px'
    },
    technologiesSectionGridContainer: {
        paddingBottom: 0
    }
};

export default technologiesSectionStyles;
