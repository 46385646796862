import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '../../../components/CustomButtons/Button.js';
import styles from './ServiceSectionStyles';
import { Typography } from '@material-ui/core';
import webSystems from '../../../images/webSystems.png';
import mobileApps from '../../../images/mobileApps.png';
import customWebSystems from '../../../images/customWebSystems.png';
import enhancement from '../../../images/enhancement.png';
import optimization from '../../../images/optimization.png';
import support from '../../../images/support.png';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import WebIcon from '@material-ui/icons/Web';
import DevicesOther from '@material-ui/icons/DevicesOther';
import Memory from '@material-ui/icons/Memory';
import SpeedIcon from '@material-ui/icons/Speed';
import SyncIcon from '@material-ui/icons/Sync';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { withRouter } from 'react-router-dom';

const WEB = 'WEB';
const DEVICES_OTHER = 'DEVICES_OTHER';
const MEMORY = 'MEMORY';
const SPEED = 'SPEED';
const SYNC = 'SYNC';
const HELP = 'HELP';

class ServiceSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderIcon = icon => {
        if (icon === WEB) {
            return <WebIcon className={this.props.classes.icons} />;
        } else if (icon === DEVICES_OTHER) {
            return <DevicesOther className={this.props.classes.icons} />;
        } else if (icon === MEMORY) {
            return <Memory className={this.props.classes.icons} />;
        } else if (icon === SPEED) {
            return <SpeedIcon className={this.props.classes.icons} />;
        } else if (icon === SYNC) {
            return <SyncIcon className={this.props.classes.icons} />;
        } else if (icon === HELP) {
            return <LiveHelpIcon className={this.props.classes.icons} />;
        }
    };

    render() {
        const { classes, t, history, hideTitle } = this.props;
        const services = [
            {
                header: t(
                    'landingPage.serviceSection.services.webSystemsDevelopment.header'
                ),
                body: t(
                    'landingPage.serviceSection.services.webSystemsDevelopment.body'
                ),
                image: webSystems,
                icon: WEB,
                pageUrl: '/web-systems-development'
            },
            {
                header: t(
                    'landingPage.serviceSection.services.mobileAppDevelopment.header'
                ),
                body: t(
                    'landingPage.serviceSection.services.mobileAppDevelopment.body'
                ),
                image: mobileApps,
                icon: DEVICES_OTHER,
                pageUrl: '/mobile-app-development'
            },
            {
                header: t(
                    'landingPage.serviceSection.services.customSoftwareDevelopment.header'
                ),
                body: t(
                    'landingPage.serviceSection.services.customSoftwareDevelopment.body'
                ),
                image: customWebSystems,
                icon: MEMORY,
                pageUrl: '/custom-software-development'
            },
            {
                header: t(
                    'landingPage.serviceSection.services.softwareMaintenanceAndEnhancement.header'
                ),
                body: t(
                    'landingPage.serviceSection.services.softwareMaintenanceAndEnhancement.body'
                ),
                image: enhancement,
                icon: SYNC,
                pageUrl: '/software-maintenance-and-enhancement'
            },
            {
                header: t(
                    'landingPage.serviceSection.services.businessWorkFlowOptimization.header'
                ),
                body: t(
                    'landingPage.serviceSection.services.businessWorkFlowOptimization.body'
                ),
                image: optimization,
                icon: SPEED,
                pageUrl: '/business-work-flow-optimization'
            },
            {
                header: t(
                    'landingPage.serviceSection.services.customerSupport.header'
                ),
                body: t(
                    'landingPage.serviceSection.services.customerSupport.body'
                ),
                image: support,
                icon: HELP,
                pageUrl: '/customer-support'
            }
        ];

        return (
            <div className={classes.section}>
                <Grid
                    container
                    justify="center"
                    className={classes.servicesTitle}
                >
                    <Grid item xs={10} sm={10} md={8}>
                        <Typography variant="h1" className={classes.title}>
                            {!hideTitle &&
                                t('landingPage.serviceSection.title')}
                        </Typography>
                        <Typography
                            variant="h4"
                            className={classes.titleWithoutColor}
                            color="textSecondary"
                        >
                            {t('landingPage.serviceSection.description')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.cardContainer}>
                    {services.map((service, idx) => {
                        return (
                            <Grid
                                item
                                key={idx + service.image}
                                xs={10}
                                sm={5}
                                md={4}
                                xl={4}
                                className={classes.cardItem}
                            >
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.media}
                                        image={service.image}
                                        title={service.header}
                                    />
                                    <CardActionArea
                                        onClick={() => {
                                            history.push(service.pageUrl);
                                        }}
                                    >
                                        <CardContent
                                            className={classes.cardContent}
                                        >
                                            {this.renderIcon(service.icon)}
                                            <Typography
                                                gutterBottom
                                                variant="h2"
                                                component="h2"
                                            >
                                                {service.header}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                            >
                                                {service.body}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button
                                            size="sm"
                                            color="accent"
                                            onClick={() => {
                                                history.push(service.pageUrl);
                                            }}
                                        >
                                            {t(
                                                'landingPage.serviceSection.learnMoreButton'
                                            )}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withRouter
)(ServiceSection);
