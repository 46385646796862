import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './CardFooterStyles.js';

const CardFooter = props => {
    const { classes, className, children, ...rest } = props;
    const cardFooterClasses = classNames({
        [classes.cardFooter]: true,
        [className]: className !== undefined
    });
    return (
        <div className={cardFooterClasses} {...rest}>
            {children}
        </div>
    );
};

CardFooter.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};

export default withStyles(styles)(CardFooter);
