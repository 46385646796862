const cookieStyles = theme => ({
    containerClasses: {
        background: '#ffffff !important',
        justifyContent: 'center !important',
        alignItems: 'center !important'
    },
    buttonClasses: {
        flex: '0 0 auto',
        padding: '5px 10px',
        margin: '15px'
    },
    contentClasses: {
        flex: 'none !important',

        [theme.breakpoints.down('xs')]: {
            flex: '1 0 300px !important'
        }
    }
});

export default cookieStyles;
