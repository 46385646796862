import { cardTitle, title, imagesStyles } from 'common/CommonStyles';

const partnersSectionStyles = {
    section: {
        textAlign: 'center'
    },
    title: {
        ...title,
        marginBottom: '1rem',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none'
    },
    ...imagesStyles,
    partnersLogo: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 150,
        height: 150,
        cursor: 'pointer'
    },
    cardTitle,
    smallTitle: {
        color: '#6c757d'
    },
    description: {
        color: '#999'
    },
    justifyCenter: {
        justifyContent: 'center !important',
        padding: 0
    },
    socials: {
        marginTop: '0',
        width: '100%',
        transform: 'none',
        left: '0',
        top: '0',
        height: '100%',
        lineHeight: '41px',
        fontSize: '20px',
        color: '#999'
    },
    margin5: {
        margin: '5px'
    },
    partnerSectionGridContainer: {
        paddingBottom: 50
    }
};

export default partnersSectionStyles;
