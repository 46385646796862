import {
    title,
    primaryColor,
    titleWithoutColor,
    selection
} from 'common/CommonStyles';

const ourValuesSectionStyles = {
    section: {
        textAlign: 'center'
    },
    title: {
        ...title,
        marginBottom: '1rem',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none'
    },
    titleWithoutColor: {
        ...titleWithoutColor,
        marginBottom: '1rem',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none'
    },
    description: {
        color: '#999'
    },
    card: {
        maxWidth: 345,
        position: 'relative',
        '&::before': {
            backgroundColor: '#651AFB',
            top: 0,
            content: 'no-close-quote',
            height: '.25em',
            left: 0,
            position: 'absolute',
            right: '80%'
        },
        '&::after': {
            backgroundColor: '#651AFB',
            bottom: 0,
            content: 'no-close-quote',
            height: '.25em',
            left: '80%',
            position: 'absolute',
            right: 0
        }
    },
    servicesTitle: {
        marginBottom: 70
    },
    icons: {
        height: 38,
        width: 38,
        color: primaryColor
    },
    cardContainer: {
        '@media (min-width: 300px)': {
            justifyContent: 'center',
            marginRight: '35px'
        },
        '@media (min-width: 576px)': {
            justifyContent: 'space-around',
            marginRight: 0
        }
    },
    cardItem: {
        paddingBottom: 50
    },
    selection: {
        ...selection
    }
};

export default ourValuesSectionStyles;
