import React from 'react';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import styles from './ParallaxStyles.js';
import { compose } from 'redux';

const Parallax = props => {
    let windowScrollTop;
    if (window.innerWidth >= 375) {
        windowScrollTop = window.pageYOffset / 3;
    } else {
        windowScrollTop = 0;
    }
    const [transform, setTransform] = React.useState(
        'translate3d(0,' + windowScrollTop + 'px,0)'
    );
    React.useEffect(() => {
        if (window.innerWidth >= 375) {
            window.addEventListener('scroll', resetTransform);
        }
        return function cleanup() {
            if (window.innerWidth >= 375) {
                window.removeEventListener('scroll', resetTransform);
            }
        };
    });
    const resetTransform = () => {
        let windowScrollTop = window.pageYOffset / 3;
        setTransform('translate3d(0,' + windowScrollTop + 'px,0)');
    };
    const {
        classes,
        filter,
        className,
        children,
        style,
        image,
        small,
        parallax
    } = props;
    const parallaxClasses = classNames({
        [classes[parallax]]: true,
        [classes.filter]: filter,
        [classes.small]: small,
        [className]: className !== undefined
    });
    return (
        <div
            className={parallaxClasses}
            style={{
                ...style,
                backgroundImage: 'url(' + image + ')',
                transform: transform
            }}
        >
            {children}
        </div>
    );
};

Parallax.propTypes = {
    className: PropTypes.string,
    filter: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.string,
    image: PropTypes.string,
    small: PropTypes.bool
};

export default compose(withStyles(styles))(Parallax);
