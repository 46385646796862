const cardFooterStyles = {
    cardFooter: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        padding: '0.9375rem 1.875rem'
    }
};

export default cardFooterStyles;
