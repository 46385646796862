import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import styles from './FooterStyles.js';
import { withStyles } from '@material-ui/core/styles';

const Footer = props => {
    const { classes, whiteFont } = props;
    const footerClasses = classNames({
        [classes.footer]: true,
        [classes.footerWhiteFont]: whiteFont
    });
    return (
        <footer className={footerClasses}>
            <div className={classes.container}>
                <div className={classes.right}>
                    <Typography variant="caption">
                        Copyright &copy; {1900 + new Date().getYear()}{' '}
                        DevelopersGen
                    </Typography>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    whiteFont: PropTypes.bool
};

export default compose(
    withStyles(styles),
    withTranslation()
)(Footer);
