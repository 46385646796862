import React from 'react';
import { compose } from 'redux';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '../../../components/CustomButtons/Button';
import Card from '../../../components/Card/Card';
import CardFooter from '../../../components/Card/CardFooter';

import styles from './PartnersSectionStyles';

import cct from '../../../images/cct.png';
import luminor from '../../../images/luminor.png';
import oz from '../../../images/oz.png';
import nextury from '../../../images/nextury.png';
import rb from '../../../images/rb.png';
import { Typography } from '@material-ui/core';
import ReactGA from 'react-ga';
import { getCookieConsentValue } from 'react-cookie-consent';
import { COOKIE_NAME } from '../../../components/Cookie/Cookie';

const outboundLink = (url, label) => {
    if (getCookieConsentValue(COOKIE_NAME)) {
        return ReactGA.outboundLink(
            {
                label: label
            },
            () => {
                window.open(url, '_blank');
            },
            ['partners']
        );
    }

    return window.open(url, '_blank');
};

const PARTNERS = [
    {
        title: 'Corner Case Technologies',
        url: 'https://www.cornercasetech.com/',
        imgSrc: cct
    },
    { title: 'Luminor', url: 'https://www.luminor.lt/', imgSrc: luminor },
    {
        title: 'Tokenizing Real Estate',
        url: 'https://www.corp.oz.io/',
        imgSrc: oz
    },
    {
        title: 'Nextury Technology Fund',
        url: 'https://www.nextury.tech/',
        imgSrc: nextury
    },
    {
        title: 'Red Bull',
        url: 'https://www.redbull.com/lt-lt/',
        imgSrc: rb
    }
];

const PartnersSection = props => {
    const { classes, t } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    return (
        <div className={classes.section}>
            <Typography variant="h1" className={classes.title}>
                {t('landingPage.partnersSection.title')}
            </Typography>
            <div>
                <Grid
                    container
                    justify="center"
                    className={classes.partnerSectionGridContainer}
                >
                    {PARTNERS.map(partner => (
                        <Grid item xs={7} sm={3} md={3} key={partner.title}>
                            <Card plain>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    className={classes.partnersLogo}
                                    onClick={() =>
                                        outboundLink(partner.url, partner.title)
                                    }
                                >
                                    <img
                                        src={partner.imgSrc}
                                        alt="..."
                                        className={imageClasses}
                                    />
                                </Grid>
                                <Typography
                                    variant="h4"
                                    className={classes.cardTitle}
                                >
                                    {partner.title}
                                </Typography>
                                <CardFooter className={classes.justifyCenter}>
                                    <Button
                                        justIcon
                                        color="transparent"
                                        className={classes.margin5}
                                        onClick={() =>
                                            outboundLink(
                                                partner.url,
                                                partner.title
                                            )
                                        }
                                    >
                                        <i
                                            className={
                                                classes.socials +
                                                ' fas fa-globe'
                                            }
                                        />
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default compose(
    withStyles(styles),
    withTranslation()
)(PartnersSection);
