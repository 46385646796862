import { title, selection } from 'common/CommonStyles';

const contactUsFormStyles = {
    section: {
        paddingBottom: 50
    },
    title: {
        ...title,
        marginBottom: '50px',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none',
        textAlign: 'center',
        '@media (min-width: 300px)': {
            marginRight: 35
        },
        '@media (min-width: 576px)': {
            marginRight: 0
        }
    },
    description: {
        textAlign: 'center',
        ...selection,
        '@media (min-width: 300px)': {
            marginRight: 35
        },
        '@media (min-width: 576px)': {
            marginRight: 0
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    textArea: {
        '@media (min-width: 300px)': {
            marginRight: '35px'
        },
        '@media (min-width: 576px)': {
            marginRight: '0'
        }
    },
    fullName: {
        '@media (min-width: 300px)': {
            paddingRight: '0'
        },
        '@media (min-width: 576px)': {
            paddingRight: '35px'
        }
    },
    buttonsContainer: {
        '@media (min-width: 300px)': {
            marginRight: '35px'
        },
        '@media (min-width: 576px)': {
            marginRight: '0'
        }
    }
};

export default contactUsFormStyles;
