const scrollDownHintStyles = {
    mainContainer: {
        position: 'relative'
    },
    subContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        textAlign: 'center'
    },
    arrows: {
        width: 60
    },
    path: {
        stroke: 'white',
        fill: 'transparent',
        strokeWidth: 3,
        animation: '$arrow 2s infinite',
        webkitAnimation: '$arrow 2s infinite'
    },
    a: {
        animationDelay: '-1s',
        webkitAnimationDelay: '-1s' /* Safari & Chrome */
    },
    a2: {
        animationDelay: '-0.5s',
        webkitAnimationDelay: '-0.5s' /* Safari & Chrome */
    },
    a3: {
        animationDelay: '0s',
        webkitAnimationDelay: '0s' /* Safari & Chrome */
    },
    '@keyframes arrow': {
        '0%': { opacity: 0 },
        '40%': { opacity: 1 },
        '80%': { opacity: 0 },
        '100%': { opacity: 0 }
    }
};
export default scrollDownHintStyles;
