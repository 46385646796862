import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './CardStyles.js';

const Card = props => {
    const { classes, className, children, plain, carousel, ...rest } = props;
    const cardClasses = classNames({
        [classes.card]: true,
        [classes.cardPlain]: plain,
        [classes.cardCarousel]: carousel,
        [className]: className !== undefined
    });
    return (
        <div className={cardClasses} {...rest}>
            {children}
        </div>
    );
};

Card.propTypes = {
    className: PropTypes.string,
    plain: PropTypes.bool,
    carousel: PropTypes.bool,
    children: PropTypes.node
};

export default withStyles(styles)(Card);
