import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { removeNotification } from 'data/actions/notificationsActions';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import { withStyles } from '@material-ui/core';
import styles from './NotificationsStyles';

class Notifications extends React.Component {
    render() {
        const { classes, removeNotification, notifications, t } = this.props;
        return (
            <div className={classes.container}>
                {notifications.map(toast => {
                    const { id, close, color, icon, messageCode } = toast;
                    return (
                        <SnackbarContent
                            key={id}
                            onDismissClick={() => removeNotification(id)}
                            message={t(messageCode)}
                            close={close}
                            color={color}
                            icon={icon}
                        />
                    );
                })}
            </div>
        );
    }
}

Notifications.propTypes = {
    removeNotification: PropTypes.func.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapDispatchToProps = dispatch => ({
    removeNotification: payload => dispatch(removeNotification(payload))
});

const mapStateToProps = state => ({
    notifications: state.notifications
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
    withTranslation()
)(Notifications);
