import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from './HowWeWorkSectionStyles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';

const ASSIGNMENTICON = 'ASSIGNMENTICON';
const WBINCANDESCENTICON = 'WBINCANDESCENTICON';
const IMPORTANTDEVICESICON = 'IMPORTANTDEVICESICON';

class HowWeWorkSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderIcon = icon => {
        if (icon === ASSIGNMENTICON) {
            return <AssignmentIcon className={this.props.classes.icons} />;
        } else if (icon === WBINCANDESCENTICON) {
            return <WbIncandescentIcon className={this.props.classes.icons} />;
        } else if (icon === IMPORTANTDEVICESICON) {
            return (
                <ImportantDevicesIcon className={this.props.classes.icons} />
            );
        }
    };

    render() {
        const { classes, t } = this.props;
        const services = [
            {
                header: t('landingPage.howWeWorkSection.strategy.title'),
                body: t('landingPage.howWeWorkSection.strategy.body'),
                icon: ASSIGNMENTICON
            },
            {
                header: t('landingPage.howWeWorkSection.solution.title'),
                body: t('landingPage.howWeWorkSection.solution.body'),
                icon: WBINCANDESCENTICON
            },
            {
                header: t('landingPage.howWeWorkSection.result.title'),
                body: t('landingPage.howWeWorkSection.result.body'),
                icon: IMPORTANTDEVICESICON
            }
        ];

        return (
            <div className={classes.section}>
                <Grid
                    container
                    justify="center"
                    className={classes.servicesTitle}
                >
                    <Grid item xs={10} sm={10} md={8}>
                        <Typography variant="h1" className={classes.title}>
                            {t('landingPage.howWeWorkSection.title')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.cardContainer}>
                    {services.map((service, idx) => {
                        return (
                            <Grid
                                item
                                key={'cardContainer' + idx}
                                xs={10}
                                sm={3}
                                md={4}
                                xl={4}
                                className={classes.cardItem}
                            >
                                <Card className={classes.card}>
                                    <CardContent>
                                        {this.renderIcon(service.icon)}
                                        <Typography
                                            gutterBottom
                                            variant="h2"
                                            component="h2"
                                            className={classes.selection}
                                        >
                                            {service.header}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.selection}
                                        >
                                            {service.body}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withRouter
)(HowWeWorkSection);
