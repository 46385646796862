import { dangerColor } from 'common/CommonStyles';

const customSelectStyles = {
    formControl: {
        '@media (min-width: 300px)': {
            minWidth: '90%'
        },
        '@media (min-width: 576px)': {
            minWidth: '100%'
        }
    },
    underline: {
        '&:after,&:before,&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid rgba(0, 0, 0, 0.42)`
        }
    },
    labelRootError: {
        color: dangerColor + ' !important'
    }
};

export default customSelectStyles;
