import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import notificationsReducer from './reducers/notificationsReducer';
import contactUsReducer from './reducers/contactUsReducer';
import careersReducer from './reducers/careersReducer';

export default combineReducers({
    form: formReducer,
    notifications: notificationsReducer,
    contactUs: contactUsReducer,
    careers: careersReducer
});
