import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';

import styles from './LandingPageStyles.js';
import { withStyles } from '@material-ui/core/styles';
import ServiceSection from '../CommonSections/Service/ServiceSection';
import WhyUsSection from '../CommonSections/WhyUs/WhyUsSection';
import PartnersSection from '../CommonSections/Partners/PartnersSection';
import HowWeWorkSection from '../CommonSections/HowWeWork/HowWeWorkSection';
import Slogan from '../../components/Slogan/Slogan';
import { HEADER_LINK_IDS } from '../../components/Header/HeaderLinks';

const LandingPage = props => {
    const { classes } = props;
    return (
        <div>
            <Header
                color="transparent"
                fixed
                changeColorOnScroll={{
                    height: 1,
                    color: 'white'
                }}
                activeTab={HEADER_LINK_IDS.HOME}
            />
            <Slogan parallax="parallax90hv" />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.sectionContainer}>
                    <ServiceSection hideTitle />
                    <HowWeWorkSection />
                    <WhyUsSection />
                    <PartnersSection />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default compose(
    withStyles(styles),
    withTranslation()
)(LandingPage);
