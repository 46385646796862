import { createMuiTheme } from '@material-ui/core/styles';

const developersGenTheme = createMuiTheme({
    fontFamily: ['Roboto', 'Turret Road'].join(','),
    typography: {
        h1: {
            fontFamily: 'Turret Road',
            fontWeight: '800',
            fontSize: '32px'
        },
        h2: {
            fontFamily: 'Turret Road',
            fontWeight: '700',
            fontSize: '24px',
            color: '#3C4858'
        },
        h4: {
            fontFamily: 'Turret Road',
            fontWeight: '700',
            fontSize: '18px'
        },
        body1: {
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '16px'
        },
        body2: {
            fontFamily: 'Turret Road',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'justify',
            hyphens: 'auto'
        },
        caption: {
            fontFamily: 'Turret Road',
            fontWeight: '700',
            fontSize: '12px'
        },
        button: {
            fontFamily: 'Turret Road',
            fontWeight: '700',
            fontSize: '12px'
        }
    }
});

export default developersGenTheme;
