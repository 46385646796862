import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import styles from './SloganStyles.js';
import { withStyles } from '@material-ui/core/styles';
import landingImage from '../../images/mainCover.jpg';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
/*import Button from "../CustomButtons/Button";*/
import Parallax from '../Parallax/Parallax';
import ScrollDownHint from '../Scroll/ScrollDownHint';

const Slogan = props => {
    const { classes, t, parallax } = props;
    return (
        <Parallax
            parallax={parallax ? parallax : 'parallax50hv'}
            filter
            image={landingImage}
        >
            <div className={classes.container}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Typography
                            variant="h1"
                            className={classes.parallaxTitle}
                        >
                            {t('landingPage.title')}
                        </Typography>
                        <Typography variant="h4" className={classes.selection}>
                            {t('landingPage.description')}
                        </Typography>
                        <br />
                        {/*TODO - think how this button would be helpful for us*/}
                        {/*<Button
              color="accent"
              size="lg"
              href="https://developersgen.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-play" />
              <Typography variant="button">
                {t("landingPage.watchVideoButton")}
              </Typography>
            </Button>*/}
                    </GridItem>
                </GridContainer>
                <ScrollDownHint />
            </div>
        </Parallax>
    );
};

export default compose(
    withStyles(styles),
    withTranslation()
)(Slogan);
