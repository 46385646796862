import React from 'react';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import styles from './WhyUsSectionStyles.js';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import enCommon from '../../../translations/en/common';

const WhyUsSection = props => {
    const { classes, t } = props;
    const reasons = Object.keys(enCommon.common.whyUs.reasons);
    return (
        <div className={classes.section}>
            <Grid container justify="center" direction="column">
                <Grid item>
                    <Typography variant="h1" className={classes.title}>
                        {t('common.whyUs.title')}
                    </Typography>
                </Grid>
                <Grid item>
                    <ul className={classes.listStyleType}>
                        <Grid container justify="space-between">
                            {reasons.map((whyUs, idx) => {
                                return (
                                    <Grid
                                        key={'reason' + idx}
                                        item
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        style={{ padding: 20 }}
                                    >
                                        <li className={classes.li}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                                className={classes.selection}
                                            >
                                                <strong
                                                    className={classes.strong}
                                                >
                                                    {t(
                                                        'common.whyUs.reasons.' +
                                                            whyUs +
                                                            '.title'
                                                    )}
                                                </strong>
                                                {t(
                                                    'common.whyUs.reasons.' +
                                                        whyUs +
                                                        '.description'
                                                )}
                                            </Typography>
                                        </li>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </ul>
                </Grid>
            </Grid>
        </div>
    );
};

export default compose(
    withStyles(styles),
    withTranslation()
)(WhyUsSection);
