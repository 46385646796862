const drawerWidth = 260;

const transition = {
    transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
};

const selection = {
    '&::selection': {
        background: '#651AFB',
        color: '#FFFFFF'
    }
};

const conatinerFluid = {
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%'
};
const container = {
    ...conatinerFluid,
    '@media (min-width: 576px)': {
        maxWidth: '540px'
    },
    '@media (min-width: 768px)': {
        maxWidth: '720px'
    },
    '@media (min-width: 992px)': {
        maxWidth: '960px'
    },
    '@media (min-width: 1200px)': {
        maxWidth: '1140px'
    }
};

const sectionContainer = {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    '@media (min-width: 576px)': {
        maxWidth: '540px'
    },
    '@media (min-width: 768px)': {
        maxWidth: '720px'
    },
    '@media (min-width: 992px)': {
        maxWidth: '960px'
    },
    '@media (min-width: 1200px)': {
        maxWidth: '1140px'
    }
};

const boxShadow = {
    boxShadow:
        '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
};

const card = {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    margin: '25px 0',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    borderRadius: '3px',
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#fff'
};

const primaryColor = '#651AFB';
const warningColor = '#ff9800';
const dangerColor = '#f44336';
const successColor = '#4caf50';
const infoColor = '#00acc1';
const accentColor = '#FD4068';
const grayColor = '#999999';

const primaryBoxShadow = {
    boxShadow:
        '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)'
};
const infoBoxShadow = {
    boxShadow:
        '0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)'
};
const successBoxShadow = {
    boxShadow:
        '0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)'
};
const warningBoxShadow = {
    boxShadow:
        '0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)'
};
const dangerBoxShadow = {
    boxShadow:
        '0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)'
};
const accentBoxShadow = {
    boxShadow:
        '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)'
};

const warningCardHeader = {
    color: '#fff',
    background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
    ...warningBoxShadow
};
const successCardHeader = {
    color: '#fff',
    background: 'linear-gradient(60deg, #66bb6a, #43a047)',
    ...successBoxShadow
};
const dangerCardHeader = {
    color: '#fff',
    background: 'linear-gradient(60deg, #ef5350, #e53935)',
    ...dangerBoxShadow
};
const infoCardHeader = {
    color: '#fff',
    background: 'linear-gradient(60deg, #26c6da, #00acc1)',
    ...infoBoxShadow
};
const primaryCardHeader = {
    color: '#fff',
    background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
    ...primaryBoxShadow
};
const accentCardHeader = {
    color: '#fff',
    background: 'linear-gradient(60deg, #ec407a, #d81b60)',
    ...accentBoxShadow
};
const cardActions = {
    margin: '0 20px 10px',
    paddingTop: '10px',
    borderTop: '1px solid #eeeeee',
    height: 'auto'
};

const cardHeader = {
    margin: '-30px 15px 0',
    borderRadius: '3px',
    padding: '15px'
};

const defaultBoxShadow = {
    border: '0',
    borderRadius: '3px',
    boxShadow:
        '0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    padding: '10px 0',
    transition: 'all 150ms ease 0s'
};

const title = {
    color: '#3C4858',
    margin: '1.75rem 0 0.875rem',
    textDecoration: 'none',
    ...selection
};

const titleWithoutColor = {
    margin: '1.75rem 0 0.875rem',
    textDecoration: 'none',
    ...selection
};

const cardTitle = {
    ...title,
    marginTop: '.625rem'
};

const cardLink = {
    '& + $cardLink': {
        marginLeft: '1.25rem'
    }
};

const cardSubtitle = {
    marginBottom: '0',
    marginTop: '-.375rem'
};

const tooltipsStyle = {
    tooltip: {
        padding: '10px 15px',
        minWidth: '130px',
        color: '#555555',
        background: '#FFFFFF',
        border: 'none',
        borderRadius: '3px',
        boxShadow:
            '0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
        maxWidth: '200px',
        textAlign: 'center',
        textShadow: 'none',
        textTransform: 'none',
        wordBreak: 'normal',
        wordSpacing: 'normal',
        wordWrap: 'normal',
        whiteSpace: 'normal',
        lineBreak: 'auto'
    }
};

const imagesStyles = {
    imgFluid: {
        maxWidth: '100%',
        height: 'auto'
    },
    imgRounded: {
        borderRadius: '6px !important'
    },
    imgRoundedCircle: {
        borderRadius: '50% !important'
    },
    imgRaised: {
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
    },
    imgGallery: {
        width: '100%',
        marginBottom: '2.142rem'
    },
    imgCardTop: {
        width: '100%',
        borderTopLeftRadius: 'calc(.25rem - 1px)',
        borderTopRightRadius: 'calc(.25rem - 1px)'
    },
    imgCardBottom: {
        width: '100%',
        borderBottomLeftRadius: 'calc(.25rem - 1px)',
        borderBottomRightRadius: 'calc(.25rem - 1px)'
    },
    imgCard: {
        width: '100%',
        borderRadius: 'calc(.25rem - 1px)'
    },
    imgCardOverlay: {
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        padding: '1.25rem'
    }
};

export {
    //variables
    drawerWidth,
    transition,
    container,
    sectionContainer,
    conatinerFluid,
    boxShadow,
    card,
    primaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    accentColor,
    grayColor,
    primaryBoxShadow,
    infoBoxShadow,
    successBoxShadow,
    warningBoxShadow,
    dangerBoxShadow,
    accentBoxShadow,
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    accentCardHeader,
    cardActions,
    cardHeader,
    defaultBoxShadow,
    title,
    cardTitle,
    cardLink,
    cardSubtitle,
    tooltipsStyle,
    imagesStyles,
    titleWithoutColor,
    selection
};
