import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import styles from './CustomInputStyles.js';
import { Typography } from '@material-ui/core';
import InputErrorWarning from '../InputError/InputErrorWarning';

const CustomInput = props => {
    const {
        classes,
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        white,
        inputRootCustomClasses,
        success,
        input,
        meta: { touched, error, warning, active }
    } = props;

    const labelClasses = classNames({
        [' ' + classes.labelRootError]: touched && !!error,
        [' ' + classes.labelRootSuccess]: success && !error
    });
    const underlineClasses = classNames({
        [classes.underlineError]: touched && !!error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.whiteUnderline]: white
    });
    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white
    });
    let formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
        );
    } else {
        formControlClasses = classes.formControl;
    }
    return (
        <FormControl {...formControlProps} className={formControlClasses}>
            {labelText !== undefined ? (
                <InputLabel
                    className={classes.labelRoot + ' ' + labelClasses}
                    htmlFor={id}
                    {...labelProps}
                >
                    <Typography variant="caption">{labelText}</Typography>
                </InputLabel>
            ) : null}
            <Input
                classes={{
                    input: inputClasses,
                    root: marginTop,
                    disabled: classes.disabled,
                    underline: underlineClasses
                }}
                id={id}
                {...inputProps}
                {...input}
            />
            <InputErrorWarning
                touched={touched}
                error={error}
                active={active}
                warning={warning}
            />
        </FormControl>
    );
};

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool
};

export default withStyles(styles)(CustomInput);
