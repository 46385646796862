import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';

import styles from './HeaderStyles.js';
import HeaderLinks from './HeaderLinks';

import logo from 'images/logo.png';
import logoInverse from 'images/logo-inverse.png';

const imgLogoInverse = (
    <img src={logoInverse} alt="DevelopersGen logo" width="201" height="32" />
);
const imgLogo = (
    <img src={logo} alt="DevelopersGen logo" width="201" height="32" />
);

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logo: this.props.color === 'transparent' ? imgLogoInverse : imgLogo,
            mobileOpen: false
        };
    }

    componentDidMount() {
        if (this.props.changeColorOnScroll) {
            window.addEventListener('scroll', this.setTimeoutHeaderColorChange);
        }
    }

    componentWillUnmount() {
        if (this.props.changeColorOnScroll) {
            window.removeEventListener(
                'scroll',
                this.setTimeoutHeaderColorChange
            );
        }
    }

    handleDrawerToggle = () => {
        this.setState(prevState => ({ mobileOpen: !prevState.mobileOpen }));
    };

    navigateHome = () => {
        this.props.history.push('/');
    };

    setTimeoutHeaderColorChange = () => {
        const _this = this;
        setTimeout(function() {
            _this.headerColorChange();
        }, 200);
    };

    headerColorChange = () => {
        const { classes, color, changeColorOnScroll } = this.props;
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            document.body
                .getElementsByTagName('header')[0]
                .classList.remove(classes[color]);
            document.body
                .getElementsByTagName('header')[0]
                .classList.add(classes[changeColorOnScroll.color]);
        } else {
            document.body
                .getElementsByTagName('header')[0]
                .classList.add(classes[color]);
            document.body
                .getElementsByTagName('header')[0]
                .classList.remove(classes[changeColorOnScroll.color]);
        }
        this.setState({
            logo:
                window.pageYOffset > changeColorOnScroll.height
                    ? imgLogo
                    : imgLogoInverse
        });
    };

    render() {
        const { classes, color, fixed, absolute, activeTab } = this.props;

        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes[color]]: color,
            [classes.absolute]: absolute,
            [classes.fixed]: fixed
        });

        return (
            <AppBar className={appBarClasses}>
                <Toolbar className={classes.container}>
                    <div className={classes.logo} onClick={this.navigateHome}>
                        {this.state.logo}
                    </div>
                    <Hidden smDown implementation="css">
                        <HeaderLinks activeTab={activeTab} />
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerToggle}
                        >
                            <Menu />
                        </IconButton>
                    </Hidden>
                </Toolbar>
                <Hidden mdUp implementation="js">
                    <Drawer
                        variant="temporary"
                        anchor={'right'}
                        open={this.state.mobileOpen}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        onClose={this.handleDrawerToggle}
                    >
                        <div className={classes.appResponsive}>
                            <HeaderLinks activeTab={activeTab} />
                        </div>
                    </Drawer>
                </Hidden>
            </AppBar>
        );
    }
}

Header.defaultProp = {
    color: 'white'
};

Header.propTypes = {
    color: PropTypes.oneOf([
        'primary',
        'info',
        'success',
        'warning',
        'danger',
        'transparent',
        'white',
        'accent',
        'dark'
    ]),
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    // this will cause the sidebar to change the color from
    // props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            'primary',
            'info',
            'success',
            'warning',
            'danger',
            'transparent',
            'white',
            'accent',
            'dark'
        ]).isRequired
    })
};

export default compose(
    withStyles(styles),
    withRouter
)(Header);
