import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import styles from './InputErrorWarningStyles';

const InputErrorWarning = ({ classes, touched, error, warning }) => {
    const renderInputWarning = () => (
        <span className={classes.warning}>
            <Typography variant="caption">{warning}</Typography>
        </span>
    );
    const renderInputError = () => (
        <span className={classes.error}>
            <Typography variant="caption">{error}</Typography>
        </span>
    );
    if (touched && error) {
        return renderInputError();
    } else if (touched && warning) {
        return renderInputWarning();
    }
    return null;
};

export default withStyles(styles)(InputErrorWarning);
