import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import configureStore from './data/reduxStore';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import theme from 'theme/DevelopersGenTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Notifications from './components/Notifications/Notifications';
import 'translations/i18next';
import App from './pages/App';
import Cookie from './components/Cookie/Cookie';

ReactDOM.render(
    <Provider store={configureStore()}>
        <MuiThemeProvider theme={theme}>
            <Notifications />
            <Router history={createBrowserHistory()}>
                <App />
            </Router>
            <Cookie />
        </MuiThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
