const enCommon = {
    landingPage: {
        title: 'You Dream IT, We Code IT',
        description:
            'DevelopersGen builds scalable systems and software you can trust. ' +
            'We offer a full suite of development services and support to fit your needs and our experienced team can skillfully tackle any project big or small.',
        watchVideoButton: 'Download white paper',
        serviceSection: {
            title: 'Services',
            description:
                'We build custom software to transform businesses across all sectors. No matter the size or technical scope, DevelopersGen will code your dreams into reality.',
            learnMoreButton: 'Learn More',
            services: {
                webSystemsDevelopment: {
                    header: 'Web Systems Development',
                    body:
                        'We offer high-quality, professional web systems that meet your needs and the needs of your customers. ' +
                        'Our unique solutions will enhance customer experience and distinguish your business from the competition. ' +
                        'From data-gathering membership systems to online booking forms, our experienced team can program anything your website needs.',
                    learnMore: {
                        firstBlock:
                            'DevelopersGen offers high-quality, professional web systems that meet your needs and the needs of your customers. ' +
                            'Our unique solutions will enhance customer experience and distinguish your business from the competition. ' +
                            'From data-gathering membership systems to online booking forms, our experienced team can program anything your website needs.',
                        contactUs:
                            'For more information on Web Systems Development, or for a free consultation, contact us today!'
                    }
                },
                mobileAppDevelopment: {
                    header: 'Mobile Applications Development',
                    body:
                        'We develop professional, reliable cross-platform mobile apps that function seamlessly across all devices. ' +
                        'We use industry-leading technology that allows us to create mobile applications more efficiently and effectively. ' +
                        'Our team delivers fast, stable and user-friendly applications with seamless UI/UX suitable for both Android and iOS.',
                    learnMore: {
                        firstBlock:
                            'DevelopersGen develops professional, reliable cross-platform mobile apps that function seamlessly across all devices. ' +
                            'We use industry-leading technology that allows us to create mobile applications more efficiently and effectively. ' +
                            'Our team delivers fast, stable and user-friendly applications with seamless UI/UX suitable for both Android and iOS.',
                        contactUs:
                            'For more information on Mobile Applications Development, or for a free consultation, contact us today!'
                    }
                },
                customSoftwareDevelopment: {
                    header: 'Custom Software Development',
                    body:
                        'From strategically built back-end to user-friendly front-end, we provide high-quality, scalable software solutions to meet your needs and the needs of your customers. ' +
                        'Our fully customizable, uniquely developed software systems can solve complex business issues and maximize effectiveness. ' +
                        'Whether you are a start-up or a large corporation, our far-from-ordinary IT solutions are the key to your business success.',
                    learnMore: {
                        firstBlock:
                            'From strategically built back-end to user-friendly frontend, DevelopersGen provides high-quality, ' +
                            'scalable software solutions to meet your needs and the needs of your customers. ' +
                            'Our fully customizable, uniquely developed websites and software systems can solve complex business issues and maximize effectiveness. ' +
                            'Whether you are a start-up or a large corporation, our far-from-ordinary IT solutions are the key to your business success',
                        contactUs:
                            'For more information on Custom Software Development, or for a free consultation, contact us today!'
                    }
                },
                softwareMaintenanceAndEnhancement: {
                    header: 'Software Maintenance and Enhancement',
                    body:
                        'Software systems are critical business components that must be constantly improved to keep up with the latest requirements and updates. ' +
                        'We fix defects in existing software solutions, implement new functionality, eliminate security vulnerabilities, ' +
                        'upgrade existing libraries, or we can program anything you envision. You dream it, we code it!',
                    learnMore: {
                        firstBlock:
                            'Software systems are critical business components that must be constantly improved to keep up with the latest requirements and updates. ' +
                            'DevelopersGen fix defects in existing software solutions, implement new functionality, eliminate security vulnerabilities, ' +
                            'upgrade existing libraries, or we can program anything you envision. You dream it, we code it!',
                        contactUs:
                            'For more information on Software Maintenance and Enhancement, or for a free consultation, contact us today!'
                    }
                },
                businessWorkFlowOptimization: {
                    header: 'Business Workflow Optimization',
                    body:
                        'A fully optimized workflow helps to reduce costs, avoid human errors, improve productivity and increase profits. ' +
                        'After analyzing your current workflow, we will diagnose potential issues and develop the best IT-based optimization solution.',
                    learnMore: {
                        firstBlock:
                            'A fully optimized workflow helps to reduce costs, avoid human errors, improve productivity and increase profits. ' +
                            'After analyzing your current workflow, DevelopersGen will diagnose potential issues and develop the best IT-based optimization solution. ',
                        contactUs:
                            'For more information on Business Workflow Optimization, or for a free consultation, contact us today!'
                    }
                },
                customerSupport: {
                    header: '24/7 Customer Support',
                    body:
                        "At DevelopersGen we build relationships, and relationships take trust. That's why we're committed to always being there when you need us. Anytime, any question, 24/7. Just send us a message and a member of our expert service team will help you out as soon as they can.",
                    learnMore: {
                        firstBlock:
                            "At DevelopersGen we build relationships, and relationships take trust. That's why we're committed to always being there when you need us. Anytime, any question, 24/7. Just send us a message and a member of our expert service team will help you out as soon as they can.",
                        contactUs:
                            'For more information on 24/7 Customer Support, or for a free consultation, contact us today!'
                    }
                }
            }
        },
        howWeWorkSection: {
            title: 'How we work?',
            strategy: {
                title: 'Strategy',
                body:
                    'No matter how far along you are in the process, we can take your dreams and make them reality. ' +
                    'We partner with clients, analyze the problem thoroughly and provide the best possible technical solutions.'
            },
            solution: {
                title: 'Solution',
                body:
                    'We deliver fully developed projects including design, architectural system solutions, and tech integration. ' +
                    'All DevelopersGen products and services are fully tested and satisfaction is guaranteed.'
            },
            result: {
                title: 'Result',
                body:
                    'We not only provide solutions, but also 24/7 support and team training to ensure future success. ' +
                    'Our goal is not just to build systems and software, but also confidence that your business has what it needs to succeed.'
            }
        },
        partnersSection: {
            title: 'Trusted Partners'
        }
    },
    contactUsPage: {
        contactUsForm: {
            title: 'Get in touch!',
            description:
                'Contact us for a free consultation and start building your digital dreams today.',
            descriptionAdditional:
                'Send us a short message about your project needs, and a member or our dedicated team of experts will get back to you soon (usually within a few hours).',
            fieldLabels: {
                fullName: 'Full Name',
                email: 'Email',
                message: 'Message'
            },
            submitButton: 'Send Message'
        }
    },
    careersPage: {
        careersForm: {
            title: "We're hiring!",
            description:
                'DevelopersGen is always looking for talented new team members. To learn more about joining our team, contact us today!',
            fieldLabels: {
                fullName: 'Full Name',
                email: 'Email',
                message: 'Comment',
                position: {
                    title: 'Position',
                    bdm: 'Business Development Manager',
                    reactDeveloper: 'React developer'
                }
            },
            weAreHiring: {
                bdm: {
                    title: 'Business Development Manager',
                    description:
                        'At DevelopersGen we build smart, scalable systems and provide consistent, top-quality solutions. We know it takes the best people to deliver the best results. As a Business Development Manager, you will be driving our business and helping our company grow. Some key responsibilities would be',
                    dayBasis: {
                        first:
                            'Analyze, diagnose, strategize and execute sales growth and business development plans',
                        second: 'Generate leads and build new relationships',
                        third:
                            'Work closely with Marketing to hone in on target markets',
                        fourth:
                            'Nurture and develop Key Account Customer relationships',
                        fifth:
                            'Provide specialist advice on products and services'
                    }
                }
            },
            submitButton: 'Join the team',
            whyJoinUs: {
                title: 'Why join us?',
                benefits: {
                    benefit: 'Competitive salary',
                    benefit2: 'Flexible working hours/remote work',
                    benefit3: 'Self-learning days – 1 per month',
                    benefit4: 'Special training and tickets to IT events'
                }
            }
        }
    },
    aboutUsPage: {
        title: 'About us',
        whoAreWe: {
            title: 'Who are we?',
            description:
                'DevelopersGen creates and develops unique, professional IT solutions and products that will drive your business to the next level. ' +
                'We are your 24/7 strategic partner and friend in this era of digitalization. We offer Web Systems Development, Mobile Applications Development, ' +
                'Custom Software Development, Software Maintenance and Enhancement, Business Workflow Optimization services, and much more! ' +
                'Contact us today for more information or a free consultation.'
        },
        redirectToContactUsButton: 'Contact us'
    },
    ourValues: {
        title: 'Our team values',
        description:
            'We at DevelopersGen strongly believe that the foundation of partnership is based on trust. Our priorities are quality, service, dependability and respect',
        teamwork: {
            title: 'Respect and empowerment of everyone'
        },
        reward: {
            title: 'Rewarding our amazing team'
        },
        quality: {
            title: 'Top quality solutions and endless imagination'
        },
        fidelity: {
            title: 'Consistency that forges trust'
        },
        passion: {
            title: 'Genuine care, passion and effort'
        },
        simplicity: {
            title: 'Simplicity over complexity'
        }
    },
    services: {
        title: 'Services',
        description: 'General description of our services.',
        redirectToContactUsButton: 'Contact us'
    },
    validations: {
        required: 'Required',
        invalidEmail: 'Invalid email address'
    },
    error: {
        somethingWentWrong: 'Something went wrong...',
        missingRequiredFields: 'Missing required fields',
        invalidEmailAddress: 'Invalid email address'
    },
    success: {
        default: 'Success',
        emailSent: 'Thank you! Your message has been sent successfully'
    },
    cookie: {
        title: 'This website uses cookies to enhance the user experience.',
        understand: 'I understand'
    },
    header: {
        services: 'Services',
        aboutUs: 'About Us',
        contactUs: 'Contact Us',
        careers: 'Careers',
        typography: 'Typography',
        notifications: 'Notifications',
        socialTooltip: {
            facebook: 'Follow us on facebook',
            instagram: 'Follow us on instagram',
            linkedin: 'Follow us on linkedin'
        }
    },
    companyDetails: {
        title: 'Company details',
        uab: 'UAB "DevelopersGen"',
        code: 'Registration code: 305135542',
        pvm: 'VAT: LT100012384019',
        address: 'Address: Girulių g. 20, Vilnius',
        bank: {
            name: 'Bank: AB, Swedbank',
            acc: 'Account: LT927300010158639281'
        },
        phone: 'Phone: +370 643 18403'
    },
    common: {
        whyUs: {
            title: 'Why DEVELOPERSGEN?',
            reasons: {
                1: {
                    title: 'UNIQUE IT SOLUTIONS - ',
                    description:
                        'DevelopersGen offers creative, innovative solutions to help you reach your business development goals.'
                },
                2: {
                    title: 'STRATEGIC PARTNERSHIP - ',
                    description:
                        'We partner with companies and clients, analyze problems as a team and offer effective solutions that work for your business.'
                },
                3: {
                    title: 'VERSATILITY - ',
                    description:
                        'We can work within a wide range of different sectors: banking, aviation, agriculture, health care, shipping, transportation, construction, energy, pharmaceutical, and more!'
                },
                4: {
                    title: 'HIGH QUALITY STANDARDS - ',
                    description:
                        'Product quality is guaranteed by experienced QA specialists and automated tests.'
                },
                5: {
                    title: 'SECURITY STANDARDS - ',
                    description:
                        'We program according to the highest security standards using special tools and embed security into the existing code.'
                },
                6: {
                    title: 'LATEST TECHNOLOGIES - ',
                    description:
                        'We work with React, React-Native, Angular, NodeJs and Java. We use Agile Scrum, but can also work with client methodologies. In addition, DevelopersGen also offers AWS Cloud Computing Services.'
                },
                7: {
                    title: 'PROJECT MANAGEMENT - ',
                    description:
                        'We use the latest software for real-time project monitoring.'
                },
                8: {
                    title: '24/7 SUPPORT - ',
                    description:
                        'Our experienced project management team is ready to assist you anytime and with any problem you encounter.'
                },
                9: {
                    title: 'IN-HOUSE TEAM OPTION - ',
                    description:
                        'DevelopersGen can work with your IT department as in-house team members to provide the most hands-on development services and solutions.'
                }
            }
        },
        technologiesBlock: {
            title: 'Core technologies we work with'
        }
    }
};

export default enCommon;
