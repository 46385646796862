import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from './en/common';

const resources = {
    en: {
        common: enCommon
    }
};

const i18nextConfig = i18next.use(initReactI18next).init({
    defaultNS: 'common',
    interpolation: { escapeValue: false }, // React already does escaping
    lng: 'en',
    resources
});

export default i18nextConfig;
