import { title, selection } from 'common/CommonStyles';

const whyUsSectionStyles = {
    section: {
        textAlign: 'center'
    },
    title: {
        ...title,
        marginBottom: '1rem',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none'
    },
    description: {
        color: '#999'
    },
    listStyleType: {
        listStyleType: 'disc',
        padding: 50
    },
    li: {
        color: '#651AFB',
        paddingBottom: 10,
        listStylePosition: 'inside',
        listStyleType: 'square'
    },
    strong: {
        color: '#651AFB',
        ...selection
    },
    selection: {
        ...selection
    }
};

export default whyUsSectionStyles;
