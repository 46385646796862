import React from 'react';
import { compose } from 'redux';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Card from '../../../components/Card/Card';
import styles from './TechnologiesSectionStyles';

import react from '../../../images/react.png';
import react_native from '../../../images/react-native.png';
import nodejs from '../../../images/nodejs.png';
import java from '../../../images/java.png';
import { Typography } from '@material-ui/core';

const TechnologiesSection = props => {
    const { classes, t } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    return (
        <div className={classes.section}>
            <Typography variant="h1" className={classes.title}>
                {t('common.technologiesBlock.title')}
            </Typography>
            <div>
                <Grid
                    container
                    justify="center"
                    className={classes.technologiesSectionGridContainer}
                >
                    <Grid item xs={5} sm={2} md={2}>
                        <Card plain>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className={classes.techLogo}
                                onClick={() =>
                                    window.open(
                                        'https://reactjs.org/',
                                        '_blank'
                                    )
                                }
                            >
                                <img
                                    src={react}
                                    alt="..."
                                    className={imageClasses}
                                />
                            </Grid>
                            <Typography
                                variant="h4"
                                className={classes.cardTitle}
                            >
                                React
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={5} sm={2} md={2}>
                        <Card plain>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className={classes.techLogo}
                                onClick={() =>
                                    window.open(
                                        'https://reactnative.dev/',
                                        '_blank'
                                    )
                                }
                            >
                                <img
                                    src={react_native}
                                    alt="..."
                                    className={imageClasses}
                                />
                            </Grid>
                            <Typography
                                variant="h4"
                                className={classes.cardTitle}
                            >
                                React Native
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={5} sm={2} md={2}>
                        <Card plain>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className={classes.techLogo}
                                onClick={() =>
                                    window.open(
                                        'https://nodejs.org/en/',
                                        '_blank'
                                    )
                                }
                            >
                                <img
                                    src={nodejs}
                                    alt="..."
                                    className={imageClasses}
                                />
                            </Grid>
                            <Typography
                                variant="h4"
                                className={classes.cardTitle}
                            >
                                NodeJS
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={5} sm={2} md={2}>
                        <Card plain>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className={classes.techLogo}
                                onClick={() =>
                                    window.open(
                                        'https://go.java/?intcmp=gojava-banner-java-com',
                                        '_blank'
                                    )
                                }
                            >
                                <img
                                    src={java}
                                    alt="..."
                                    className={imageClasses}
                                />
                            </Grid>
                            <Typography
                                variant="h4"
                                className={classes.cardTitle}
                            >
                                Java
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default compose(
    withStyles(styles),
    withTranslation()
)(TechnologiesSection);
